.saveMisc {
  margin-top: var(--unit-24);
  margin-right: var(--unit-16);
}

.header {
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--unit-16);
}

.headerText {
  flex: 3;
}

.headerTextDescription {
  color: var(--color-content-secondary-bg-primary);
  margin-top: var(--unit-8);
}

.headerTextDescription > a {
  text-decoration: underline;
  color: var(--color-content-primary);
}

.headerBtn {
  margin-left: var(--unit-24);
}

.errorsCallout {
  margin-bottom: var(--unit-16);
}

.formField {
  margin-bottom: var(--unit-24);
}

.tableAvatarWrapper {
  display: flex;
  align-items: center;
  gap: var(--unit-8);
}

.avatar {
  display: inline-block;
  margin-right: var(--unit-8);
}

.defaultForm__deprecated {
  margin-top: var(--unit-16);
  margin-bottom: var(--unit-40);
}

.defaultForm__input__deprecated {
  margin-top: var(--unit-24);
}

.defaultForm__description {
  color: var(--color-content-secondary-bg-primary);
  margin-top: var(--unit-8);
}

.defaultForm__inputWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: var(--unit-16);
}

.defaultForm__input:first-child {
  margin-right: var(--unit-8);
}

.defaultForm__input:last-child {
  margin-left: var(--unit-8);
}

.defaultForm__input {
  margin-top: var(--unit-24);
  flex: 1 1 50%;
}

.defaultForm__select {
  margin-top: var(--unit-24);
  flex: 1 1 50%;
}

.defaultForm__submit {
  margin-top: var(--unit-24);
  margin-right: var(--unit-16);
}

.formCallout {
  margin-top: var(--unit-8);
}

.formCalloutInModal {
  margin-top: calc(-1 * var(--unit-16));
}
