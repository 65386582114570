@container (width > 1280px) {
  .EntityItemActions__Container {
    width: 60%;
  }
}

@container (width <= 1280px) {
  .EntityItemActions__Container {
    width: 100%;
  }
}
