:root {
  --left-max-width: 545px;
  --vertical-padding: 100px;
}

.block {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: var(--vertical-padding) var(--unit-32);
  overflow-y: auto;
}

.leftContent {
  max-width: min(100%, var(--left-max-width));
}
