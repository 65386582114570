.IntegrationAccountingPage__section-title {
  color: var(--color-content-primary);
}

.IntegrationAccountingPage__employee-accounts-individual-employees {
  margin-top: var(--unit-32);
}

.IntegrationAccountingPage__section-description {
  color: var(--color-content-secondary-bg-primary);
  margin-top: var(--unit-8);
  padding-bottom: var(--unit-16);
}

.IntegrationAccountingPage__section-description > a {
  text-decoration: underline;
  color: var(--color-content-primary);
}
