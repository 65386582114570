.CompanyPlanNewWallet {
  width: 132px;
  border: none;
  background-color: var(--color-background-secondary-brand-default);
  border-radius: var(--border-radius-4);
  cursor: pointer;
  height: 100%;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  padding: var(--unit-12);
  align-items: center;
  justify-content: center;
}

.CompanyPlanNewWallet.isDisabled {
  background-color: var(--color-background-secondary-default);
  cursor: not-allowed;
  border: 1px solid var(--color-border-default);
}
