.done {
  text-decoration: line-through;
  color: var(--color-content-secondary-bg-secondary);
}

.circle {
  height: var(--unit-24);
  width: var(--unit-24);
  border: 2px solid var(--color-border-default);
  border-radius: 50%;
}

.circlePending {
  border-style: dotted;
}
