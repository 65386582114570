.AccountPayableSuppliersRowIcon__icon-container {
  margin-top: 10px;
  position: relative;
}

.AccountPayableSuppliersRowIcon__icon-container-single {
  margin: var(--unit-8) var(--unit-16) 0 0;
}

.AccountPayableSuppliersRowIcon__icon-single {
  width: var(--unit-32);
  height: var(--unit-32);
  border-radius: var(--border-radius-4);
  border: 1px var(--color-border-default) solid;
  position: relative;
  z-index: 1;
}

.AccountPayableSuppliersRowIcon__icon {
  width: 26px;
  height: 26px;
  border: 1px var(--color-border-default) solid;
  border-radius: var(--border-radius-4);
  position: relative;
  z-index: 1;
}

.AccountPayableSuppliersRowIcon__icon-background-img {
  width: var(--unit-24);
  height: var(--unit-24);
  background-color: var(--color-background-secondary-default);
  display: block;
  left: 6px;
  position: absolute;
  top: -6px;
  border-radius: var(--border-radius-4);
  z-index: 0;
}
