/*
 * Grapes hacking time: making <button> look like Grapes component
 */

.CostCenterCreationMethodSelectionModal__selection {
  background: none;
  width: 245px;
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-4);
  cursor: pointer;
  transition:
    background-color 0.2s ease-in,
    border-color 0.2s ease-in;
}

.CostCenterCreationMethodSelectionModal__selection:focus {
  outline: none;
}

.CostCenterCreationMethodSelectionModal__selection:focus-visible {
  outline: var(--focus-ring);
  outline-offset: 0px;
}

.CostCenterCreationMethodSelectionModal__selection:hover {
  border-color: var(--color-border-hover);
  box-shadow: var(--elevation-s);
}

.CostCenterCreationMethodSelectionModal__selection.selected {
  background-color: var(--color-background-secondary-brand-default);
  border-color: transparent;
}
