.preloginModal h1,
.chooseSubsidiaryModal h1 {
  margin-top: 0px;
  padding-top: var(--unit-8);
}

.infoWrapper {
  color: var(--color-content-primary);
  text-align: left;
  margin-top: calc(-1 * var(--unit-16));
}

.stepsList {
  color: var(--color-content-brand-default);
  padding-bottom: var(--unit-16);
  padding-left: var(--unit-16);
}

.stepsList li {
  padding-top: var(--unit-16);
}

.bottomText {
  margin-bottom: var(--unit-24);
}

.integrationIllustrationContainer {
  width: 100%;
  margin-top: var(--unit-24);
  margin-bottom: var(--unit-8);
  text-align: center;
}

.integrationIllustrationContainer__mvpFlow {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--unit-16);
}

.integrationIllustration {
  display: inline-block;
}

.integrationIllustrationAvatar {
  width: var(--unit-56);
  height: var(--unit-56);
}

.integrationIllustrationAvatar > span:nth-child(2) {
  height: 30px;
  width: 30px;
  margin-bottom: calc(-1 * var(--unit-4));
  margin-right: calc(-1 * var(--unit-4));
}

.integrationIllustrationAvatar > span:nth-child(2) {
  height: 30px;
  width: 30px;
  background-color: var(--color-background-primary-default);
  border-radius: 50%;
}
.integrationIllustrationIcon {
  color: var(--color-content-brand-default);
  background-color: var(--color-background-primary-default);
  text-align: center;
}

.steps {
  color: var(--color-content-primary);
  margin-top: var(--unit-8);
  display: inline-block;
}

.step {
  color: var(--color-content-primary);
  margin-top: var(--unit-24);
}

.step a {
  color: var(--color-content-primary);
  text-decoration: underline;
}

.stepInfo {
  color: var(--color-content-primary);
  margin-top: var(--unit-4);
  display: inline-block;
}

.stepInfo a {
  color: var(--color-content-primary);
  text-decoration: underline;
}

.subtitle {
  color: var(--color-content-primary);
  margin-bottom: var(--unit-40);
  margin-top: var(--unit-16);
  text-align: center;
}

.subtitle a {
  color: var(--color-content-primary);
  text-decoration: underline;
}

.helpButton svg {
  color: var(--color-content-secondary-bg-secondary);
}

.netsuiteModal .subtitle {
  margin-bottom: var(--unit-16);
}

.netsuiteModal .step {
  margin-top: 0;
}

.netsuiteModal .formField {
  margin-top: var(--unit-16);
}
