.placeholder {
  background: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: var(--unit-48);
  transition: transform var(--transition-duration-long)
    var(--transition-timing-function);
  width: calc(100% - var(--unit-40));
}

.placeholder:hover {
  transform: scale(1.05);
}
