.ApprovalPolicyDetailsContent__form {
  margin-top: var(--unit-24);
  text-align: left;
}

.ApprovalPolicyDetailsContent__field {
  margin-bottom: var(--unit-24);
}

.ApprovalPolicyDetailsContent__field__label {
  margin-bottom: var(--unit-8);
  display: block;
}

.ApprovalPolicyDetailsContent__field__addition {
  background-color: var(--color-background-secondary-default);
  border: 1px solid var(--color-border-default);
  padding: var(--unit-16);
  border-radius: var(--border-radius-4);
  margin: var(--unit-8) 0;
}

.ApprovalPolicyDetailsContent__field__addition__text {
  line-height: var(--unit-32);
  border-bottom: 1px solid var(--color-border-default);
  padding-bottom: var(--unit-16);
  margin-bottom: var(--unit-16);
}

.ApprovalPolicyDetailsContent__field__money-input {
  margin: 0 var(--unit-8);
  max-width: 100px;
}

.ApprovalPolicyDetailsContent__field__money-input input {
  width: 100%;
}

.ApprovalPolicyDetailsContent__field__error {
  margin-top: var(--unit-8);
}

.ApprovalPolicyDetailsContent__field__error:last-child {
  margin-bottom: 0;
}
