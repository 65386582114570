.modal .Modal-wrapper {
  width: 90vw !important;
}
.modal .Modal-content {
  position: relative;
  height: 90vh;
  padding: 0 !important;
}

.modalContent {
  position: relative;
  height: 90vh;
  width: 90vw;
  padding: 0;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

.iframe {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: var(--border-radius-4);
}
