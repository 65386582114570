.MapSkeletonContainer {
  border: 1px solid var(--color-border-default);
  min-height: 170px;
  position: relative;
  border-radius: var(--border-radius-8);
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.MapSkeleton {
  position: absolute;
  height: 100%;
  width: 100%;
}
