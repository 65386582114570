.topBanner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--unit-16);
  min-height: 72px;
  gap: var(--unit-16);
  background-color: var(
    --_bg-color,
    var(--color-background-primary-alert-default)
  );
}

.title {
  font: var(--body-m);
  color: var(--_color, var(--color-content-complementary));
}

.topBanner[data-variant='info'] {
  --_color: var(--color-content-primary);
  --_bg-color: var(--color-background-secondary-info-default);
}

.topBanner[data-variant='complementary'] {
  --_bg-color: var(--color-background-complementary-default);
}
