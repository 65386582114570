.MembersTableHeader__countContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.MembersTableHeader__countContainer_title {
  display: flex;
  align-items: center;
  gap: var(--unit-8);
  color: var(--color-content-primary);
  line-height: 20px;
}
.MembersTableHeader__countContainer_count {
  color: var(--color-content-primary);
}
