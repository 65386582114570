.GraphDashboard__graphPlaceholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-image: url('/static/img/homepage/placeholder-graph.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}
