.DropdownSwitcherContent__active__header {
  color: var(--color-content-selected);
  background-color: var(--color-background-primary-selected);
  border-radius: var(--border-radius-4);
  padding: var(--unit-8);
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.DropdownSwitcherContent__active__header__left {
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.DropdownSwitcherContent__active__details {
  margin-top: var(--unit-16);
  padding-top: var(--unit-16);
  border-top: 1px var(--color-border-default) solid;
}

.DropdownSwitcherContent__items {
  margin: 0 var(--unit-16);
  padding: var(--unit-8) 0;
  padding-top: 0;
  border-bottom: 1px var(--color-border-default) solid;
  max-height: calc(4 * 33.5px + 2 * var(--unit-8));
  overflow: auto;
}

.DropdownSwitcherContent__items__button {
  text-decoration: none;
  outline: none;
  border: none;
  background: none;
  font-family: var(--ff-base);
  transition: background-color var(--transition-duration)
    var(--transition-timing-function);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: var(--color-content-primary);
  padding: var(--unit-8);
  border-radius: var(--border-radius-4);
  cursor: pointer;
}
.DropdownSwitcherContent__items__button:hover {
  background-color: var(--color-background-primary-hover);
  color: var(--color-content-primary);
}
.DropdownSwitcherContent__items__button:active {
  background-color: var(--color-background-secondary-default);
  color: var(--color-content-primary);
}

.DropdownSwitcherContent__items__button__left {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.DropdownSwitcherContent__items__button__right {
  flex: 0 0 auto;
}
