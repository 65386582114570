.FileViewerToolbar {
  height: var(--unit-40);
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: var(--unit-8);
  margin: 0;
  border-bottom: 1px solid var(--color-border-default);
  background: var(--color-background-primary-default);
  color: var(--color-content-primary);
  line-height: 1;
}

.FileViewerToolbar__right {
  margin-left: auto;
  display: flex;
  align-items: center;
  column-gap: var(--unit-8);
}
