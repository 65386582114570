.MarqetaSetPin {
  width: 320px;
  margin: auto;
}

.MarqetaSetPin__loader {
  display: block;
  margin: auto;
}

.MarqetaSetPin__iframe {
  width: 320px;
  height: 280px;
  display: block;
}

.MarqetaSetPin__iframe--loading {
  display: none;
}
