.CellWithButton {
  position: relative;
  width: 100%;
  min-height: 36px;
  display: flex;
  align-items: center;
}

.CellWithButton .CellWithButton__button {
  position: absolute;
  right: 0;
}
