.PlasticCardControlSettings__column {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: var(--unit-8);
}

.PlasticCardControlSettings__title {
  display: flex;
  align-items: center;
  gap: var(--unit-8);
  color: var(--color-content-primary);
}

.PlasticCardControlSettings__row {
  display: flex;
  justify-content: space-between;
  color: var(--color-content-secondary-bg-primary);
}
