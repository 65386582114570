.ExpenseAccountImportError {
  border-bottom: solid 1px var(--color-border-default);
  padding-bottom: var(--unit-24);
  margin-bottom: var(--unit-24);
}

.ExpenseAccountImportError h3 {
  color: #000;
}

.ExpenseAccountImportError:last-child {
  border-bottom: none;
}
