.viewer {
  position: fixed;
  inset-block: 0;
  left: 0;
  width: calc(100% - var(--size-side-panel));
  z-index: var(--z-index-side-panel);
}

.viewer:empty {
  display: none;
}

.viewer :global(.ImageViewer),
.viewer :global(.PDFViewer) {
  border-radius: 0;
}
