.AccountingBaseSwitchOptions__icons {
  position: relative;
}

.AccountingBaseSwitchOptions__first-icon {
  margin-top: var(--unit-12);
  z-index: 2;
  margin-right: 14px;
  width: 18px;
  height: 18px;
}

.AccountingBaseSwitchOptions__second-icon {
  position: absolute;
  left: 6px;
  top: 6px;
  z-index: 0;
  width: 18px;
  height: 18px;
}

.AccountingBaseSwitchOptions__icon-background {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 2px;
  top: 10px;
  z-index: 1;
  background-color: var(--color-background-primary-default);
  border-top-right-radius: 5px;
}
