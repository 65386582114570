.navigation-company-header,
.navigation-company-switcher {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: var(--unit-40);
  padding: var(--unit-4);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-8);
  text-align: start;
  width: 100%;
  overflow-x: clip;
  outline: none;
  background-color: var(--color-background-primary-default);
  color: var(--color-content-primary);
  font: var(--body-m);
}

.navigation-company-switcher:focus-visible {
  outline: var(--focus-ring);
  outline-offset: 0px;
}

.navigation-company-switcher {
  cursor: pointer;
  --_caret-size: var(--unit-32);
}

.navigation-company-switcher:is(:hover, :focus-visible) {
  background: var(--color-background-primary-hover);
}

.navigation-company-content {
  opacity: 0;
  width: calc(
    var(--ui-size-nav-expanded) - var(--unit-32) -
      var(--_caret-size, var(--unit-8))
  );
}

.navigation-company-content-shrink {
  flex-shrink: 0;
  width: var(--unit-32);
  opacity: 1;
}

.navigation-company-translate {
  --_translate-x-base: calc(-1 * (var(--unit-24)));
  flex-shrink: 0;
  translate: calc(var(--_translate-x-base) - 2ch) 0;
}

#vertical:hover .navigation-company-content-shrink {
  opacity: 0;
}

#vertical:hover .navigation-company-translate {
  translate: var(--_translate-x-base) 0;
  opacity: 1;
}

@media (prefers-reduced-motion: no-preference) {
  .navigation-company-content-shrink {
    transition: opacity var(--transition-duration-long)
      var(--transition-timing-function);
  }
  .navigation-company-translate {
    transition:
      opacity var(--transition-duration) var(--transition-timing-function),
      translate var(--transition-duration) linear(0, 0.8 50%, 1);
  }
}

@container navigation (width > 230px) {
  .navigation-company-content-shrink {
    opacity: 0;
  }
  .navigation-company-translate {
    translate: var(--_translate-x-base) 0;
    opacity: 1;
  }
}
