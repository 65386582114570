.MemberDetailsModal__header__container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: var(--unit-8);
  padding-top: var(--unit-8);
}

.MemberDetailsModal__header__name {
  color: var(--color-content-primary);
  margin-top: var(--unit-8);
  line-height: var(--unit-24);
}

.MemberDetailsModal__verticalDivider {
  border-left: 1px solid var(--color-border-default);
  width: 0;
  height: var(--unit-16);
  margin: 0 var(--unit-8);
}

.MemberDetailsModal__main__heading {
  color: var(--color-content-primary);
  padding: var(--unit-8) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.MemberDetailsModal__row {
  display: flex;
  align-items: center;
  margin-top: var(--unit-16);
  gap: var(--unit-16);
}

.MemberDetailsModal__row__label {
  color: var(--color-content-primary);
  width: 150px;
  max-width: 150px;
}

.MemberDetailsModal__row__valueContainer {
  background-color: var(--color-background-secondary-default);
  border-radius: var(--border-radius-4);
  padding: var(--unit-8) var(--unit-16);
  flex: 1;
}

.MemberDetailsModal__warningContainer {
  background-color: var(--color-background-secondary-warning-default);
  color: var(--color-content-warning-default);
}

.MemberDetailsModal__actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--unit-24);
}
