.invoiceNumber,
.supplierNameAndAddress,
.table,
.myNameAndAddress,
.vat,
.total {
  transition: transform 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.animatedReceipt .invoiceNumber {
  transform: translate(-15px, -20px) scale(1.5);
}

.animatedReceipt .myNameAndAddress {
  transform: translate(-94px, -20px) scale(1.5);
  transition-delay: var(--animation-delay);
}

.animatedReceipt .supplierNameAndAddress {
  transform: translate(-15px, -40px) scale(1.5);
  transition-delay: calc(var(--animation-delay) * 2);
}

.animatedReceipt .table {
  transform: translate(6px, 100px) scale(1.3);
  transition-delay: calc(var(--animation-delay) * 3);
}

.animatedReceipt .vat {
  transform: translate(-93px, -100px) scale(1.5);
  transition-delay: calc(var(--animation-delay) * 4);
}

.animatedReceipt .total {
  transform: translate(-93px, -95px) scale(1.5);
  transition-delay: calc(var(--animation-delay) * 5);
}
