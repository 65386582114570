.header {
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--unit-16);
}

.headerText {
  flex: 3;
}

.headerTextDescription {
  color: var(--color-content-secondary-bg-primary);
  margin-top: var(--unit-8);
}

.headerTextDescription > a {
  text-decoration: underline;
  color: var(--color-content-primary);
}

.reverseChargeSection {
  margin-top: var(--unit-32);
}
