.MemberEditTabInformation__row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--unit-16);
}

.MemberEditTabInformation__row:not(:first-of-type) {
  padding-top: var(--unit-24);
}

.MemberEditTabInformation__row.MemberEditTabInformation__row--full {
  grid-template-columns: 100%;
}

.MemberEditTabInformation__bank-info-block {
  border-top: 1px solid var(--color-border-default);
  text-align: left;
  margin-top: var(--unit-24);
}
