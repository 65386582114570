.NoAccountingIntegrationFeedback {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
  padding: var(--unit-64) 0 var(--unit-40) 0;
  background-color: var(--color-background-primary-default);
  color: var(--color-content-primary);
  height: 100%;
  overflow: auto;
}

.NoAccountingIntegrationFeedback__container {
  text-align: center;
  height: 100%;
}

.NoAccountingIntegrationFeedback__title {
  color: var(--color-content-primary);
  margin: 0 auto var(--unit-16) auto;
}

.NoAccountingIntegrationFeedback__content {
  max-width: 496px;
  margin: 0 auto var(--unit-24) auto;
}

.NoAccountingIntegrationFeedback__features {
  margin: var(--unit-48) 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NoAccountingIntegrationFeedback__features-arrow {
  margin: 0 var(--unit-16) var(--unit-64) var(--unit-16);
}

.NoAccountingIntegrationFeedback__features-content {
  margin-top: var(--unit-8);
  max-width: 250px;
}

.NoAccountingIntegrationFeedback__features-cta {
  margin-bottom: var(--unit-24);
}
