.root {
  background: radial-gradient(
    circle at 100% 100%,
    #cfd5fa 0,
    #eae4fa 50%,
    #f8f8f8 70%
  );
  border-radius: var(--border-radius-12);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @media (min-width: 925px) {
    flex-direction: row;
  }
}

.content {
  align-self: self-start;
  max-width: 536px;
  padding: var(--unit-24);
}

.title {
  line-height: 25.16px;
}

.description {
  line-height: 18.2px;
}

.logo {
  position: absolute;
  bottom: 25px;
  right: 25px;
}
