.container {
  border: 1px solid var(--color-border-default);
  display: flex;
  flex-direction: column;
  border-radius: var(--container-radius);
  background-color: var(--color-background-primary-default);
  padding: var(--unit-24);
  overflow: visible;
  height: 502px;
}
