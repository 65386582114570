.ApproversList {
  display: flex;
  flex-direction: column;
  align-content: stretch;
}

.ApproversList__item {
  --item-height: var(--unit-48);
  --item-padding: var(--unit-8);
  --item-index-border-width: 1px;
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--unit-8);
  min-height: calc(var(--item-height) + var(--unit-8));
  padding: var(--item-padding);
  border-radius: var(--border-radius-4);
}

.ApproversList__item__icons {
  display: none;
  margin-left: auto;
  flex-shrink: 0;
}

.ApproversList__item .ApproversList__item__details__index {
  --item-index-length: var(--unit-32);
  height: var(--item-index-length);
  width: var(--item-index-length);
  border-radius: var(--border-radius-4);
  color: var(--color-content-info-default);
  background-color: var(--color-background-secondary-info-default);
  border: var(--item-index-border-width) solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin-right: var(--unit-16);
  flex-shrink: 0;
}

.ApproversList__item .ApproversList__item__details__name {
  color: var(--color-content-primary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
  max-width: 220px;
}

.ApproversList__item.ApproversList__item--all-following {
  --line-left-position: calc(
    var(--item-padding) + var(--item-index-length) / 2 -
      var(--item-index-border-width)
  );
}

.ApproversList__item.ApproversList__item--all-following::after {
  content: '';
  position: absolute;
  top: 0;
  left: 23px;
  width: 1px;
  height: 100%;
  border-left: 1px solid transparent;
  z-index: 1;
}

.ApproversList__item.ApproversList__item--all-following:last-of-type::after {
  height: 50%;
}

.ApproversList__item.ApproversList__item--all-following:first-of-type:last-of-type::after {
  border: none;
}

.ApproversList__item:hover {
  background-color: var(--color-background-primary-hover);
}

.ApproversList__item:hover .ApproversList__item__icons {
  display: inline-block;
}
