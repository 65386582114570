.TemplateEditorForm__badge {
  width: var(--unit-32);
  height: var(--unit-32);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-content-brand-default);
  border: 1px solid transparent;
  background-color: var(--color-background-secondary-brand-default);
  border-radius: 50%;
}
