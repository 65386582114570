.container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.scanContainer {
  padding: var(--unit-16);
  max-width: 192px;
  width: 25%;
  border: 0.5px solid var(--color-border-default);
  border-radius: var(--border-radius-8);
  background-color: var(--color-background-primary-default);
}

.badges {
  max-height: 52px;
}

.caroleCooper {
  position: absolute;
  max-height: 95%;
  max-width: 271px;
  width: 25%;
  right: 0;
  bottom: 0;
}
