.AffidavitPurchaseInfoModal__input__grid {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: var(--unit-8);
}

.AffidavitPurchaseInfoModal__input__grid__thoroughfare {
  grid-column: 1 / 9;
}

.AffidavitPurchaseInfoModal__input__grid__zip {
  grid-column: 1 / 3;
}

.AffidavitPurchaseInfoModal__input__grid__city {
  grid-column: 3 / 6;
}

.AffidavitPurchaseInfoModal__input__grid__country {
  grid-column: 6 / 9;
}
