/*  re-init table display, otherwise we lose the "table" layout */
.MembersApprovalPolicies thead {
  display: table-header-group;
}

.MembersApprovalPolicies thead tr th {
  height: 44px;
  white-space: nowrap;
}
.MembersApprovalPolicies tbody {
  display: table-row-group;
}

.MembersApprovalPolicies tr {
  display: table-row;
}

.MembersApprovalPolicies td {
  white-space: normal;
  overflow: visible;
  text-overflow: initial;
}
