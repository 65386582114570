.PerDiemDaysBreakdown_DestinationDetailsBox {
  display: grid;
  grid-template-columns: var(--unit-32) 1fr auto;
  align-items: center;
}

.PerDiemDaysBreakdown_DestinationDetailsBox__dashedLine {
  position: absolute;
  top: calc(100% + 10px);
  left: 2px;
  border-radius: 34px;
}
