.CompanyDropdownLimitedAccess__progress-bar {
  margin-top: var(--unit-16);
  height: var(--unit-8);
  background-color: var(--color-background-secondary-default);
  border-radius: var(--border-radius-4);
}

.CompanyDropdownLimitedAccess__progress-bar__progress {
  height: 100%;
  background-color: var(--color-background-primary-success-default);
  border-radius: var(--border-radius-4);
}
