.BillingInformationForm {
  margin-top: var(--unit-16);
  background-color: var(--color-background-primary-default);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-8);
  padding: var(--unit-24);
  width: 100%;
}

.BillingInformationForm__field-container {
  display: flex;
  margin-top: var(--unit-24);
}

.BillingInformationForm__form-field {
  flex: 1 1 50%;
  margin: 0;
  position: relative;
}

.BillingInformationForm__form-field:not(:first-child) {
  flex: 1 1 50%;
  margin-left: var(--unit-16);
}
