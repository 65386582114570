.formCallout {
  margin-top: var(--unit-8);
}

.pullErrorCallout {
  margin-bottom: var(--unit-16);
}

.editBankAccountCodeWarning {
  margin-top: var(--unit-8);
}

.hasDefaultSfsBankAccountCodeWarning {
  margin-bottom: var(--unit-16);
}

.nameContainer {
  display: flex;
  align-items: center;
}

.name {
  margin-right: var(--unit-8);
}

.foreignEschangeAccountsSection {
  margin-top: var(--spacing-l);
}
