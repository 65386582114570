.Modal {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(23, 17, 78, 0.7);
  z-index: var(--z-index-modal);
  overflow: scroll;
  padding: var(--unit-16);
}

/* --- Sizes --- */

.Modal.small .Modal-wrapper {
  width: 400px;
}

.Modal.medium .Modal-wrapper {
  width: 600px;
}

.Modal.large .Modal-wrapper {
  width: 900px;
}

/* --- Styling --- */

.Modal .Modal-wrapper {
  min-height: 200px;
  background-color: var(--color-background-primary-default);
  border-radius: var(--border-radius-4);
  margin: auto;
  position: static;
  transform: none;
  overflow: visible;
  max-height: none;
}

.Modal .Modal-wrapper .Modal-title-wrapper {
  position: relative;
  padding: var(--unit-24) var(--unit-24) var(--unit-32) var(--unit-24);
}

.Modal .Modal-wrapper .Modal-title-wrapper .Modal-title {
  text-align: left;
  font-size: var(--unit-24);
  font-family: var(--ff-base);
  font-weight: var(--fw-semibold);
  line-height: 30px;
  color: var(--color-content-primary);
  max-width: calc(100% - var(--unit-20));
}

.Modal .Modal-wrapper .Modal-title-wrapper .Modal-close {
  position: absolute;
  top: 33px;
  right: var(--unit-24);
}

.Modal .Modal-wrapper .Modal-content {
  padding: var(--unit-24);
  padding-top: 0;
}

.Modal .Modal-wrapper .Modal-content .Modal-actions {
  margin-top: var(--unit-48);
  display: flex;
}

.Modal .Modal-wrapper .Modal-content .Modal-actions.ModalActions--centered {
  justify-content: center;
}

.Modal .Modal-wrapper .Modal-content .Modal-actions.ModalActions--right {
  justify-content: flex-end;
}

.Modal .Modal-wrapper .Modal-content .Modal-actions button:first-child {
  margin-left: 0;
}

.Modal .Modal-wrapper .Modal-content .Modal-actions button {
  margin-left: 24px;
}
