.invitationResults {
  max-height: 55vh;
  overflow: auto;
  text-align: left;
}

.messageRow {
  text-wrap: wrap;
  margin: 10px;
}
