svg.spinner {
  background: none;
}

svg.spinner rect {
  fill: var(--color-content-brand-default);
}

svg.spinner.grey rect {
  fill: var(--color-content-primary);
}

svg.spinner.white rect {
  fill: var(--color-content-complementary);
}
