@tailwind base;

@tailwind components;

@tailwind utilities;

@import '@dev-spendesk/grapes/style' layer(grapes);

/** Grapes hacking time: Disable overlay animation **/
div[data-closing][class^='_grapes'] {
  animation: none;
}

:root {
  --ui-size-nav: var(--unit-64);
  --ui-size-nav-expanded: 233px;
}

:root[data-nav-expanded='1'] {
  --ui-size-nav: var(--ui-size-nav-expanded);
  --ui-elevation-nav: none;
}
