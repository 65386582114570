.ModalReminder__upload-rule-list {
  list-style: '- ';
  list-style-position: inside;
}

.ModalReminder_expander {
  margin: 0 -24px;
  border-top: 1px solid var(--color-border-default);
}

.ModalReminder_expander .opener {
  display: flex;
  align-items: center;
  padding: 0 var(--unit-24);
  height: var(--unit-48);
  cursor: pointer;
  font-weight: var(--fw-semibold);
  user-select: none;
}

.ModalReminder_expander .opener:focus-visible {
  outline: var(--focus-ring);
}

.ModalReminder_expander .opener .caret {
  transition: 0.14s ease transform;
}

.ModalReminder_expander .opener.opened .caret {
  transform: rotate(90deg);
}

.ModalReminder_expander .expander-content {
  padding: 0 var(--unit-24) var(--unit-24);
}
