.customFieldNameField {
  margin-bottom: var(--unit-16);
}

.analyticalField {
  margin-top: var(--unit-16);
}

.analyticalField__callout {
  text-align: left;
  padding: var(--unit-8);
  border-radius: var(--border-radius-4);
}

.analyticalField__content {
  text-align: left;
}

.analyticalField__text {
  margin-bottom: var(--unit-8);
}

.analyticalField__link {
  display: block;
  margin-top: var(--unit-4);
  color: var(--color-content-primary);
  text-decoration: underline;
  cursor: pointer;
}

/* Grapes hacking time: callout without icon */
.analyticalField__noIcon > div *:nth-child(1) > svg {
  display: none;
}

.analyticalField__noIcon > div *:nth-child(2) {
  margin-left: 0px;
}

.uppercase {
  text-transform: uppercase;
}
