.CostCentersPage {
  padding-bottom: var(--unit-24);
}

.CostCentersPage__owner-cell {
  display: flex;
  align-items: center;
}

.CostCentersPage__owner-cell-avatar {
  margin-right: var(--unit-8);
}

.CostCentersPage__owner-cell__actions {
  margin-left: auto;
  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--unit-16);
  margin-left: var(--unit-8);
}

.CostCentersPage__owner-cell__text {
  flex-grow: 1;
  width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
