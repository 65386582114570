.MarqetaSetPinModal {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  overflow: hidden;
}

.MarqetaSetPinModal .Modal-wrapper {
  /*  Overriding to match exact iframe size */
  width: 368px !important;
  height: 358px !important;

  display: flex;
  flex-direction: column;
}

.MarqetaSetPinModal .Modal-wrapper .Modal-title-wrapper {
  padding-bottom: 0;
}

.MarqetaSetPinModal .Modal-wrapper .Modal-content {
  flex-grow: 1;
  display: flex;
}
