.PreviewApprovalPolicyModal__field {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.PreviewApprovalPolicyModal__field__policy {
  color: var(--color-content-primary);
  margin-top: var(--unit-8);
  background-color: var(--color-background-secondary-default);
  border-radius: var(--border-radius-4);
  padding: var(--unit-16);
}
