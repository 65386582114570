.AvatarMenu__dropdown-item {
  border-radius: var(--border-radius-8);
  transition: background-color var(--transition-duration)
    var(--transition-timing-function);
}

.AvatarMenu__dropdown-item:hover {
  background-color: var(--color-background-primary-hover);
}

.AvatarMenu__dropdown-item > * {
  display: block;
  font: var(--body-m);
  padding: var(--unit-8) var(--unit-12);
  color: var(--color-content-secondary-bg-primary);
}

.AvatarMenu__dropdown {
  display: flex;
  flex-direction: column;
  gap: var(--unit-8);
  padding: var(--unit-8);
  border-radius: inherit;
  overflow: hidden;
  min-width: 225px;
}
