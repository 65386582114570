.AccountingBaseSelect__form {
  width: 100%;
  text-align: left;
  margin-top: var(--unit-16);
}

.AccountingBaseSelect__dropdown {
  width: 100%;
  margin-bottom: var(--unit-16);
  position: relative;
}

.AccountingBaseSelect__preview {
  text-align: center;
}

.AccountingBaseSelect__integrationType {
  color: var(--color-content-primary);
}

.IntegrationInfoCallout__content {
  color: var(--color-content-primary);
}

.IntegrationInfoCallout__warning {
  color: var(--color-content-primary);
}
