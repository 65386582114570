.fields {
  margin-bottom: var(--unit-16);
}

.field {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--unit-8);
}

.fieldName {
  color: var(--color-content-primary);
}

.fieldValue {
  color: var(--color-content-primary);
}
