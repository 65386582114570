.codeInUseCallout {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: var(--unit-8) var(--unit-16);
}

.codeInUseCallout__text {
  text-align: left;
}
