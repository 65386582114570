.AffidavitSignatureLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.AffidavitSignatureLoader__content {
  display: block;
  text-align: center;
}
