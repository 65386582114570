/*  This makes the icon rotate in the modal */
.modal [role='img'] {
  animation: rotating 1.5s linear infinite;
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}
