.details {
  height: var(--unit-40);
  overflow: clip;
  overflow-clip-margin: 3px;
}

.details[open] .caret {
  transform: rotate(90deg);
}

.details[open] {
  height: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .details[open] {
    transition: height var(--transition-duration)
      var(--transition-timing-function);
  }
}

.safariFix::-webkit-details-marker {
  display: none;
}

.list {
  display: flex;
  flex-direction: column;
  gap: var(--unit-4);
  padding: var(--unit-4) 0;
}

@container navigation (width < 230px) {
  .list {
    display: none;
  }
}
