.SiderPageLayout,
.SiderPageLayout__viewer {
  --panelWidth: 368px;
}

.SiderPageLayout {
  display: flex;
  gap: var(--unit-24);
  width: 100%;
  min-height: 100%;
  padding: var(--unit-24) var(--unit-40);
  background-color: var(--color-background-primary-default);
  overflow-y: auto;
}

.SiderPageLayout__panel {
  position: sticky;
  top: 0;
  width: var(--panelWidth);
}

.SiderPageLayout__content {
  flex: 1;
}

/* Add some space at the bottom of the content, to compensate the padding not taken into account while using overflow auto  */
.SiderPageLayout__content:after {
  content: '';
  display: block;
  height: var(--unit-24);
  width: 100%;
}

.SiderPageLayout__panel--emphasized {
  z-index: 9999;
}

.SiderPageLayout__viewer {
  position: fixed;
  inset: 0;
  padding-right: calc(var(--panelWidth) + var(--unit-24));
  background-color: rgba(23, 17, 78, 0.6);
  z-index: 9998;
}

.SiderPageLayout__viewer-content {
  display: flex;
  justify-content: center;
  padding: var(--unit-48) var(--unit-24);
  height: 100%;
}

.SiderPageLayout__viewer-content .LegacyPDFViewer {
  width: 100%;
  height: 100%;
}
