.CustomFieldModalInfo__content {
  text-align: left;
}

.CustomFieldModalInfo__content__field {
  margin-bottom: var(--unit-24);
}

.CustomFieldModalInfo__content__field:last-child {
  margin-bottom: 0;
}

.CustomFieldModalInfo__content__callout {
  margin-top: var(--unit-24);
}
