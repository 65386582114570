.searchList::highlight(primary) {
  color: var(--color-content-brand-default);
  text-decoration: underline;
}

.search[open] {
  translate: 0 5vh;
  opacity: 1;
}

.search {
  width: min(80vw, 500px);
  margin: 0 auto;
  border: none;
  border-radius: var(--border-radius-8);
  background: var(--color-background-primary-default);
  font: var(--fw-medium) var(--docs-fs-body-m) var(--docs-ff-base);
  opacity: 0;
  translate: 0 0;
  transition:
    opacity var(--transition-duration),
    translate var(--transition-duration),
    display var(--transition-duration) allow-discrete,
    overlay var(--transition-duration) allow-discrete;
}

.search::backdrop {
  background: #17114eb3;
}

.search a {
  text-decoration: none;
}

/* Order matter, should be placed after .search[open] and .search  */
@starting-style {
  .search[open] {
    translate: 0 0;
    opacity: 0;
  }
}

.searchBody {
  padding-inline: var(--unit-24);
}

.searchInputWrapper {
  display: flex;
  align-items: center;
  gap: var(--unit-16);
  padding-block: var(--unit-16);
  border-block-end: 1px solid var(--color-border-default);
}

.searchInputWrapper input {
  display: block;
  flex: 1;
  border: none;
  font-size: var(--docs-fs-body-m);
}

.searchList {
  display: flex;
  gap: var(--unit-8);
  padding-block: var(--unit-24);
  flex-direction: column;
}

.searchList [role='option'] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--color-background-primary-default);
  padding: var(--unit-16) var(--unit-24);
  border-radius: var(--border-radius-8);
  border: 1px solid var(--color-border-default);
  color: var(--color-content-primary);
}
.searchList a:focus-visible [role='option'] {
  border: 1px solid transparent;
  background: var(--color-background-secondary-brand-default);
}
.searchList [role='option']:is(:hover) {
  border: 1px solid var(--color-border-hover);
  color: var(--color-content-brand-hover);
}
.searchList [role='option']:is([aria-selected='true']) {
  border: 1px solid transparent;
  background: var(--color-background-secondary-brand-default);
}
