.TemplateBuilder-wrapper {
  position: relative;
  overflow-x: auto;
  height: 495px;
  padding-top: 28px;
  margin-bottom: var(--unit-20);
}
.is-editing .TemplateBuilder-wrapper {
  pointer-events: none;
}
.TemplateBuilder {
  position: absolute;
  display: flex;
  white-space: nowrap;
}
.TemplateBuilder--is-loading .TemplateBuilder__column-rows {
  opacity: 0.3;
}
.TemplateBuilder--is-loading .action-left,
.TemplateBuilder--is-loading .action-right {
  display: none;
}
.TemplateBuilder--is-loading
  .TemplateBuilder__column-head
  .TemplateBuilder__column-settings {
  visibility: hidden;
}
.TemplateBuilder
  .TemplateBuilder__column:first-child
  .TemplateBuilder__column-actions {
  border-top-left-radius: var(--border-radius-4);
  border-bottom-left-radius: var(--border-radius-4);
}
.TemplateBuilder
  .TemplateBuilder__column:first-child
  .TemplateBuilder__column-actions
  .action-left {
  pointer-events: none;
}
.TemplateBuilder
  .TemplateBuilder__column:first-child
  .TemplateBuilder__column-head {
  border-top-left-radius: var(--border-radius-4);
}
.TemplateBuilder
  .TemplateBuilder__column:first-child
  .TemplateBuilder__column-rows
  .TemplateBuilder__column-rows-item:last-child {
  border-bottom-left-radius: var(--border-radius-4);
}
.TemplateBuilder
  .TemplateBuilder__column:last-child
  .TemplateBuilder__column-actions,
.TemplateBuilder
  .TemplateBuilder__column:last-child
  .TemplateBuilder__column-head,
.TemplateBuilder
  .TemplateBuilder__column:last-child
  .TemplateBuilder__column-rows-item {
  border-right: 1px solid var(--color-border-default);
}
.TemplateBuilder
  .TemplateBuilder__column:last-child
  .TemplateBuilder__column-actions {
  border-top-right-radius: var(--border-radius-4);
  border-bottom-right-radius: var(--border-radius-4);
}
.TemplateBuilder
  .TemplateBuilder__column:last-child
  .TemplateBuilder__column-head {
  border-top-right-radius: var(--border-radius-4);
}
.TemplateBuilder
  .TemplateBuilder__column:last-child
  .TemplateBuilder__column-rows
  .TemplateBuilder__column-rows-item:last-child {
  border-bottom-right-radius: var(--border-radius-4);
}
.TemplateBuilder .TemplateBuilder__column:last-child .action-right img {
  right: 9px !important;
}
.TemplateBuilder
  .TemplateBuilder__column:last-child
  .TemplateBuilder__column-actions
  .action-right
  .blue-separator {
  right: 14px;
  border-radius: 0 var(--border-radius-4) var(--border-radius-4) 0;
  height: 419px;
  top: var(--unit-56);
}
.TemplateBuilder .TemplateBuilder__column {
  background-color: var(--color-background-primary-default);
  white-space: nowrap;
  float: none;
  cursor: grab;
  position: relative;
  user-select: none;
  /* Top actions: Add column in the left / right + remove column */
}
.TemplateBuilder
  .TemplateBuilder__column:hover
  .TemplateBuilder__column-head
  .title
  + .TemplateBuilder__column-settings {
  opacity: 1 !important;
}
.TemplateBuilder
  .TemplateBuilder__column.is-dragged
  .TemplateBuilder__column-actions,
.TemplateBuilder
  .TemplateBuilder__column.is-selected
  .TemplateBuilder__column-actions {
  background-color: var(--color-background-secondary-info-default) !important;
}
.TemplateBuilder
  .TemplateBuilder__column.is-dragged
  .TemplateBuilder__column-actions::before,
.TemplateBuilder
  .TemplateBuilder__column.is-selected
  .TemplateBuilder__column-actions::before {
  display: block;
}
.TemplateBuilder
  .TemplateBuilder__column.is-dragged
  .TemplateBuilder__column-head,
.TemplateBuilder
  .TemplateBuilder__column.is-selected
  .TemplateBuilder__column-head {
  background-color: var(--color-background-secondary-info-default);
}
.TemplateBuilder
  .TemplateBuilder__column.is-dragged
  .TemplateBuilder__column-rows,
.TemplateBuilder
  .TemplateBuilder__column.is-selected
  .TemplateBuilder__column-rows {
  background-color: var(--color-background-secondary-info-default);
}
.TemplateBuilder .TemplateBuilder__column.is-dragged {
  cursor: grabbing;
  border-right: 1px solid var(--color-border-default);
  z-index: 1;
}
.TemplateBuilder
  .TemplateBuilder__column.is-dragged
  .TemplateBuilder__column-actions
  .action-left::before,
.TemplateBuilder
  .TemplateBuilder__column.is-dragged
  .TemplateBuilder__column-actions
  .action-left::after {
  opacity: 0;
}
.TemplateBuilder
  .TemplateBuilder__column.is-dragged
  .TemplateBuilder__column-actions
  .action-right::before,
.TemplateBuilder
  .TemplateBuilder__column.is-dragged
  .TemplateBuilder__column-actions
  .action-right::after {
  opacity: 0;
}
.TemplateBuilder .TemplateBuilder__column.new .TemplateBuilder__column-head {
  background-color: var(--color-background-secondary-default);
}
.TemplateBuilder
  .TemplateBuilder__column.new
  .TemplateBuilder__column-head
  .title {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
.TemplateBuilder .TemplateBuilder__column .TemplateBuilder__column-actions {
  margin-bottom: 9px;
  background-color: var(--color-background-primary-default);
  border-top: 1px solid var(--color-border-default);
  border-bottom: 1px solid var(--color-border-default);
  border-left: 1px solid var(--color-border-default);
  height: var(--unit-16);
  display: flex;
  cursor: pointer;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions:hover {
  background-color: var(--color-background-primary-hover);
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions::before {
  content: '';
  cursor: grab;
  z-index: 1;
  position: absolute;
  display: none;
  top: 5px;
  left: 50%;
  width: 21px;
  height: 6px;
  transform: translateX(-50%);
  background-image: url('/static/img/icons/drag.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 21px 6px;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-left,
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-right {
  flex: 0.5;
  position: absolute;
  top: -30px;
  width: 50%;
  height: 45px;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-left
  .blue-separator,
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-right
  .blue-separator {
  position: absolute;
  opacity: 0;
  width: 2px;
  height: 420px;
  top: 55px;
  background-color: var(--color-background-secondary-info-default);
  z-index: 1;
  transition: opacity 0.2s ease;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-left
  .plus-sign,
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-right
  .plus-sign {
  opacity: 0;
  position: absolute;
  top: 5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-left:hover
  .blue-separator,
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-right:hover
  .blue-separator,
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-left:hover
  .plus-sign,
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-right:hover
  .plus-sign {
  opacity: 1;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-left {
  left: 0;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-left
  .blue-separator {
  left: 0px;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-left
  .plus-sign {
  left: -7px;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-right {
  right: -14px;
  z-index: 1;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-right
  .blue-separator {
  right: var(--unit-12);
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-actions
  .action-right
  .plus-sign {
  right: 7px;
}
.TemplateBuilder .TemplateBuilder__column .TemplateBuilder__column-head {
  background-color: var(--color-background-secondary-default);
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--color-border-default);
  border-right: none;
  padding: 0 var(--unit-8);
  position: relative;
}
.TemplateBuilder .TemplateBuilder__column .TemplateBuilder__column-head .title {
  white-space: nowrap;
  color: var(--color-content-primary);
  font-weight: var(--fw-medium);
  font-size: 13px;
  line-height: 15px;
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid transparent;
  margin-right: var(--unit-4);
  padding: 5px 7px;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-head
  .title
  + .TemplateBuilder__column-settings {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.1s ease;
  cursor: pointer;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-head
  .choose-column {
  position: absolute;
  top: 38px;
  z-index: 1;
  left: 0;
  width: 300px;
  max-width: 270px;
  background-color: var(--color-background-primary-default);
  box-shadow: 0 2px 10px 0 rgba(43, 44, 47, 0.1);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-4);
  white-space: initial;
  line-height: 20px;
  font-size: 13px;
  font-weight: var(--fw-semibold);
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-head
  .choose-column {
  z-index: 2;
  cursor: initial;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-head
  .choose-column
  .choose-column__search {
  display: flex;
  align-items: center;
  gap: var(--unit-8);
  justify-content: space-between;
  width: 100%;
  padding: var(--unit-8) var(--unit-16);
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-head
  .choose-column
  .choose-column__search
  svg {
  cursor: pointer;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-head
  .choose-column
  .choose-column__results {
  max-height: 175px;
  overflow-y: scroll;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-head
  .choose-column
  .choose-column__results.no-results {
  padding: 0px 15px var(--unit-12) 15px;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-head
  .choose-column
  .choose-column__results
  .choose-column__results-item {
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: 0;
  background: none;
  color: inherit;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  line-height: 22px;
  font-size: 14px;
  font-weight: var(--fw-medium);
  font-family: var(--ff-base);
  height: 35px;
  cursor: pointer;
  padding: 3px 15px;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-head
  .choose-column
  .choose-column__results
  .choose-column__results-item:hover {
  background-color: var(--color-background-primary-hover);
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-rows
  .TemplateBuilder__column-rows-item {
  white-space: nowrap;
  color: var(--color-content-primary);
  font-weight: var(--fw-medium);
  font-size: 13px;
  line-height: 15px;
  padding: 13px 15px;
  border-bottom: 1px solid var(--color-border-default);
  border-left: 1px solid var(--color-border-default);
  min-height: 42px;
}
.TemplateBuilder
  .TemplateBuilder__column
  .TemplateBuilder__column-rows
  .TemplateBuilder__column-rows-item.is-empty {
  background: var(--color-background-secondary-default);
}

.TemplateBuilderV2 {
  width: 100%;
  position: relative;
  border-radius: var(--border-radius-4);
  overflow: visible;
}
.TemplateBuilderV2.stick-left::before {
  opacity: 0;
}
.TemplateBuilderV2.stick-right::after {
  opacity: 0;
}
.TemplateBuilderV2::before,
.TemplateBuilderV2::after {
  content: '';
  position: absolute;
  display: block;
  width: var(--unit-16);
  height: calc(100% - 65px);
  bottom: 0;
  opacity: 0.2;
  transition: opacity 0.2s ease;
  z-index: 10;
}
.TemplateBuilderV2::before {
  left: 0;
  z-index: 1;
  background-image: linear-gradient(
    90deg,
    var(--color-background-complementary-default) 0%,
    transparent 85%
  );
}
.TemplateBuilderV2::after {
  right: 0;
  background-image: linear-gradient(
    90deg,
    transparent 0%,
    var(--color-background-complementary-default) 85%
  );
}

.TemplateBuilder__loader {
  position: absolute;
  top: calc(495px / 2); /*  495px = table's height */
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}
