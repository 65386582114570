.GringottsIframe {
  width: 350px;
  height: 100%;
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 1000000;
  border: 1px solid var(--color-border-default);
}
