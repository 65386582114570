.CompanyDropdownWalletDetails__amount-line {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.CompanyDropdownWalletDetails__allocated-details {
  color: var(--color-text-secondary-bg-primary);
  margin-bottom: var(--unit-16);
  position: relative;
}

.CompanyDropdownWalletDetails__allocated-details__line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--unit-24);
}
