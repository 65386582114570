@container (width > 1280px) {
  .EntityItem__LayoutWrapper {
    display: flex;
    align-items: stretch;
    gap: var(--unit-16);
  }
}

@container (width <= 1280px) {
  .EntityItem__LayoutWrapper {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    gap: var(--unit-20);
  }
}
