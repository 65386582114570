.PayableStateFilter__button {
  color: var(--color-content-primary);
  font-weight: var(--fw-medium);
}

.PayableStateFilter__checkbox {
  padding: var(--unit-4) var(--unit-8);
}

.PayableStateFilter__button--open {
  background-color: var(--color-background-secondary-default);
}

.PayableStateFilter__button--active {
  color: var(--color-content-brand-default);
  font-weight: var(--fw-semibold);
}
