.NotificationsStack {
  position: fixed;
  inset-inline: 0;
  inset-block-start: var(--unit-8);
  z-index: calc(var(--z-index-modal) + 1);
}

.NotificationStackContainer {
  position: relative;
  list-style: none;
  margin: 0 auto;
  list-style: none;
  width: var(--size-toast);
}

.NotificationToast {
  --_transition-entry-duration: 0.4s;
  --_transition-exit-duration: 0.5s;
  --_transition-timing: ease-in-out;
  position: absolute;
  top: var(--translate, 0px);
  width: 100%;
  opacity: 1;
  translate: 0 0;
  z-index: 2;
  padding-top: var(--unit-8);
  transition:
    top var(--_transition-entry-duration) var(--_transition-timing),
    translate var(--_transition-entry-duration) var(--_transition-timing),
    opacity var(--_transition-entry-duration) var(--_transition-timing);
}

.NotificationToast[data-index='-1'] {
  top: var(--translate, 0px);
  opacity: 0;
  translate: 0 -100px;
  z-index: 1;
  transition:
    translate var(--_transition-exit-duration) var(--_transition-timing),
    opacity var(--_transition-exit-duration) var(--_transition-timing);
}

@starting-style {
  .NotificationToast:not([data-index='-1']) {
    opacity: 0;
    translate: 0 -100%;
  }
  .NotificationToast[data-index='-1'] {
    opacity: 1;
    translate: 0 0;
  }
}
