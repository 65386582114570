.panelLoader {
  width: 368px;
  height: 100%;
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-8);
  background-color: var(--color-background-primary-default);
  overflow: auto;
}

.panelLoaderNavigation {
  display: flex;
  position: sticky;
  z-index: 10;
  top: calc(var(--unit-8) - var(--unit-24));
  align-items: center;
  justify-content: space-between;
  padding: var(--unit-24) var(--unit-24) var(--unit-8) var(--unit-24);
  margin-bottom: var(--unit-16);
  background-color: var(--color-background-primary-default);
  border-bottom: 1px solid transparent;
}
