.PDFViewer {
  --viewer-toolbar-height: var(--unit-40);
  height: 100%;
  position: relative;
  border-radius: var(--border-radius-8);
  overflow: hidden;
  background: var(--color-background-primary-default);
}

.PDFViewer__iframe {
  height: calc(100% - var(--viewer-toolbar-height));
  width: 100%;
  border: 0;
}

.PDFViewer--withoutToolbar .PDFViewer__iframe {
  height: 100%;
}

.PDFViewer__iframe-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 75%;
}
