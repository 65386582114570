.page {
  display: flex;
  justify-content: center;
  padding: var(--unit-40) 0;

  background-color: var(--color-background-primary-default);
  flex-grow: 1;
}

.sidebar {
  margin-right: var(--unit-32);
  width: 232px;
}

.section {
  width: 760px;
}

.sectionDescriptionLine {
  width: 100%;
  height: 18px;
  margin-bottom: var(--unit-24);
}

.wrapper {
  background-color: var(--color-background-primary-default);
  border: 1px solid var(--color-border-default);
  box-sizing: border-box;
  border-radius: var(--border-radius-4);
  padding: var(--unit-24);
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: var(--unit-24);
}

.actions {
  display: flex;
  height: 36px;
  justify-content: flex-end;
}
