.CostCenterImportModal__step {
  display: flex;
  align-items: center;
  justify-content: start;

  text-align: left;
  color: var(--color-content-primary);
  margin-bottom: var(--unit-8);
  gap: var(--unit-8);
}

.CostCenterImportModal__file_container .Upload__link {
  color: var(--color-content-primary);
  font-weight: var(--fw-semibold);
  text-decoration: underline;
}

.CostCenterImportModal__file_container_error:first-child {
  border-color: var(--color-border-alert);
}
