.header {
  border-bottom: 1px solid var(--color-border-default);
}

.line {
  background-color: var(--color-background-primary-success-default);
  border-radius: 1px;
  flex: 1;
  height: 2px;
}

.line.linePending {
  background-color: var(--color-background-secondary-default);
}

.line.lineWarning {
  background-color: var(--color-background-primary-warning-default);
}
