.CardDesign__card {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: 'OCR A W00 Extended', monospace, serif;
}

.CardDesign__disabled:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2a264f; /* check grapes colors */
  opacity: 0.9;
  border-radius: var(--border-radius-12);
}

.CardDesign__physical {
  background-image: url('/static/img/card-design/physical.svg');
}

.CardDesign__physical.CardDesign__visa {
  background-image: url('/static/img/card-design/physical-visa.svg');
}

.CardDesign__physical.CardDesign__activating {
  font-family: var(--ff-base);
  background-image: url('/static/img/card-design/physical-back.svg');
  font-weight: 300;
}

.CardDesign__physical.CardDesign__activating span {
  display: inline-block;
  text-align: center;
}

.CardDesign-disabled {
  display: flex;
  height: 23px;
  padding-right: 6px;
  padding-left: var(--unit-20);
  align-items: center;
  color: #464080; /* check color with product */
  background-color: var(--color-background-primary-default);
  font-family: var(--ff-base);
  font-weight: var(--fw-semibold);
  border-radius: 16px;
  z-index: 1;
}
.CardDesign-disabled__blocked:after,
.CardDesign-disabled__ordered:after,
.CardDesign-disabled__limited:after,
.CardDesign-disabled__restricted:after {
  content: '';
  position: absolute;
  left: var(--unit-8);
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #ee625e; /* check color with product */
}

.CardDesign-disabled__ordered:after {
  background-color: #a3a3b5; /* check color with product */
}

.CardDesign-disabled__limited:after {
  background-color: #ffa745; /* check color with product */
}

.CardDesign__name,
.CardDesign__expDate,
.CardDesign__numbers,
.CardDesign__chip,
.CardDesign-disabled {
  position: absolute;
}
.CardDesign__name,
.CardDesign__expDate,
.CardDesign__numbers {
  color: white;
  text-shadow: 0px 1px rgba(42, 38, 79, 0.5);
}
