.MemberDisplay {
  display: flex;
  align-items: center;
  padding-left: var(--unit-8);
  border-radius: var(--border-radius-8);
  height: 42px;
}

.MemberDisplay_name {
  margin-left: var(--unit-8);
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.MemberDisplay_teams {
  color: var(--color-content-primary);
  margin-left: var(--unit-4);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 220px;
}

.MemberDisplay_pending {
  margin-left: var(--unit-8);
}

.MemberDisplay_action {
  display: none;
  margin-right: var(--unit-4);
  margin-left: auto;
  background-color: var(--color-background-secondary-default)-alpha;
}
.MemberDisplay.MemberDisplay--hoverable:hover {
  background-color: var(--color-background-primary-hover);
}
.MemberDisplay.MemberDisplay--hoverable:hover .MemberDisplay_action {
  display: block;
}
