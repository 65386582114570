.description {
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-4);
  padding: var(--unit-16);
  margin-bottom: var(--unit-16);
  display: flex;
  flex-direction: column;
  gap: var(--unit-4);
  color: var(--color-content-primary);
}

.descriptionTitle {
  font-weight: var(--fw-semibold);
  color: var(--color-content-primary);
}
