.LegacySubscriptionItems {
  width: 100%;
  margin-bottom: var(--unit-20);
  padding-left: var(--unit-20);
  list-style: disc;
}

.LegacySubscriptionItems:last-of-type {
  margin-bottom: 0;
}
