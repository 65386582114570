.viewer {
  --panelWidth: 368px;
  position: fixed;
  inset: 0;
  padding-right: calc(var(--panelWidth) + var(--unit-64));
  background-color: rgba(23, 17, 78, 0.6);
  z-index: 9998;
}

.viewerInCreationPanel {
  padding-right: 0;
}

.viewerContent {
  display: flex;
  justify-content: center;
  padding: var(--unit-48) var(--unit-24);
  height: 100%;
}
