.AccountPayableSuppliersNameCell__name {
  display: flex;
  align-items: center;
  width: 100%;
}

.AccountPayableSuppliersNameCell__label-multiple-suppliers {
  margin-left: var(--unit-24);
}

.AccountPayableSuppliersNameCell__label-no-supplier {
  margin-left: var(--unit-16);
  color: var(--color-content-primary);
}
