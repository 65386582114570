.SFSCardDesign__card {
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: var(--ff-base);
}

.SFSCardDesign__disabled:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-background-complementary-default);
  opacity: 0.8;
  border-radius: var(--border-radius-12);
}

.SFSCardDesign__tag {
  position: absolute;
  display: flex;
  padding: var(--unit-4) var(--unit-8);
  align-items: center;
  background-color: var(--color-background-secondary-info-default);
  border: solid 1px transparent;
  color: var(--color-content-info-default);
  border-radius: 199px;
  z-index: 1;
}

.SFSCardDesign__tag-blocked,
.SFSCardDesign__tag-limited,
.SFSCardDesign__tag-restricted {
  background-color: var(--color-background-secondary-alert-default);
  border: solid 1px transparent;
  color: var(--color-content-alert-default);
}
