/** Global reset **/
@layer reset {
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    background-color: var(--color-background-primary-default);
    font-feature-settings: 'cpsp' 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-content-primary);
    font-family: var(--ff-base);
    font-weight: var(--fw-medium);
    font-size: var(--font-size-14);
    line-height: 1.6923;
  }

  a {
    text-decoration: none;
    outline: none;
  }

  a:is(:hover, :focus, .active) {
    outline: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4 {
    font-weight: normal;
  }

  b,
  strong {
    font-weight: inherit;
  }

  input,
  textarea,
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    resize: none;
  }

  /* Remove the cross button on search input on Chrome and Safari */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}
