.CardActivation {
  flex: 1;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background-primary-default);
  padding: 50px 0;
}

/* Remove after SFS Migration */
.CardActivation > div {
  display: none;
}

/* Remove after SFS Migration */
.CardActivation > div:first-child {
  display: block;
}
