.RecardActivation .CardDesign__card {
  position: absolute;
  top: 95px;
  left: 59px;
}

.RecardActivated .CardDesign__card {
  transition: var(--transition-duration) var(--transition-timing-function);
  position: relative;
  top: 0;
  left: 0;
  margin: 0 auto var(--unit-24) auto;
  transform: scale(1);
}

.RecardActivated .CardDesign__card:hover {
  transform: scale(0.97);
  cursor: pointer;
}
