.CustomFieldValuesList__row__actions {
  transition: opacity var(--transition-duration)
    var(--transition-timing-function);
  opacity: 0;
}

.CustomFieldValuesList__row {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
}

.CustomFieldValuesList__row.CustomFieldValuesList__row--virtual {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid var(--color-border-default);
}

.CustomFieldValuesList__row.CustomFieldValuesList__row--virtual:last-child {
  border-bottom: none;
}

.CustomFieldValuesList__row.CustomFieldValuesList__row--virtual
  .CustomFieldValuesList__header__title {
  margin-right: var(--unit-16);
}

.CustomFieldValuesList__row.CustomFieldValuesList__row--virtual
  .CustomFieldValuesList__row__name {
  margin-left: var(--unit-16);
  font-size: var(--body-m);
  color: var(--color-content-primary);
}

.CustomFieldValuesList__row.CustomFieldValuesList__row--virtual
  .CustomFieldValuesList__row__actions {
  margin-right: var(--unit-16);
}

.CustomFieldValuesList__row:hover .CustomFieldValuesList__row__actions {
  opacity: 1;
}

.CustomFieldValuesList__list {
  max-height: 300px;
  width: 100%;
  overflow: auto;
}

.CustomFieldValuesList__list__virtual {
  width: 100%;
  position: relative;
}

.CustomFieldValuesList__header__title {
  color: var(--color-content-primary);
}

.CustomFieldValuesList__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--unit-16);
}

.CustomFieldValuesList__header__actions {
  display: flex;
  gap: var(--unit-8);
}

.CustomFieldValuesList__header__actions__dropdown {
  white-space: nowrap;
}
