.TransferListRow {
  display: flex;
  align-items: center;
}

.TransferListRow__counter-party {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.TransferListRow__counter-party__details {
  margin-left: var(--unit-16);
}

.TransferListRow__counter-party__name {
  color: var(--color-content-primary);
}

.TransferListRow__counter-party__invoice-number {
  margin-top: var(--unit-4);
  color: var(--color-content-secondary-bg-primary);
}

.TransferListRow__counter-party__invoice-number--highlight {
  color: var(--color-content-primary);
}

.TransferListRow__amount {
  color: var(--color-content-primary);
}

.TransferListRow__details {
  text-align: right;
  white-space: nowrap;
  flex-basis: 85px;
}

.TransferListRow__actions {
  margin-left: var(--unit-16);
}
