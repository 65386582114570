:root {
  --arrow-height: var(--unit-20);
  --arrow-margin: calc(-1 * var(--unit-24) + var(--arrow-height) / 2);
}

.leftStart {
  align-items: flex-start;
  margin-top: var(--arrow-margin);
}

.leftEnd {
  align-items: flex-end;
  margin-bottom: var(--arrow-margin);
}

.bottomEnd {
  align-items: flex-end;
  flex-direction: column-reverse;
  margin-right: var(--arrow-margin);
}

.bottomStart {
  align-items: flex-start;
  flex-direction: column-reverse;
  margin-left: var(--arrow-margin);
}
