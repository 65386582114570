.approvalThreshold {
  width: 100%;
  margin-bottom: var(--unit-16);
}

.header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-border-default);
  padding: var(--unit-8) var(--unit-16);
  padding-top: var(--unit-16);
  padding-bottom: var(--unit-16);
  margin: calc(-1 * var(--unit-16));
  margin-bottom: var(--unit-16);
}

.header:hover .delete {
  display: block;
}

.delete {
  display: none;
  width: var(--unit-32);
  height: var(--unit-32);
  border-radius: var(--border-radius-4);
}

.upToInput {
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-4);
  outline: none;
  margin-left: var(--unit-8);
  padding: var(--unit-8);
  height: var(--unit-32);
  font-size: var(--font-size-14);
}

.upToInput {
  appearance: none; /* Chrome, Edge */
  -moz-appearance: textfield; /*// Firefox*/
}

.upToInput::-webkit-inner-spin-button,
.upToInput::-webkit-outer-spin-button {
  /* Safari */
  -webkit-appearance: none;
  margin: 0;
}

.upToCurrency {
  position: absolute;
  right: var(--unit-8);
  top: 6px;
  color: var(--color-content-secondary-bg-secondary);
}

.hasError {
  border: 1px solid transparent;
  border-radius: var(--border-radius-4);
}

.fadeOut {
  animation-name: approvalThresholdFadeOut;
  animation-duration: 4s;
}

@keyframes approvalThresholdFadeOut {
  0% {
    background-color: rgba(
      var(--color-background-secondary-warning-default),
      0.1
    );
    border: 1px solid transparent;
  }
  100% {
    background-color: var(--color-background-primary-default);
    border: 1px solid var(--color-border-default);
  }
}

.blink {
  animation: approvalThresholdBlink 0.15s step-start 0s 4;
  background-color: rgba(var(--color-background-secondary-alert-default), 0.1);
}

@keyframes approvalThresholdBlink {
  50% {
    border: 1px solid transparent;
  }
}
