.AccountPayableSuppliersSubList {
  flex: 1 0 368px;
  margin-left: var(--unit-24);
  position: sticky;
  align-self: flex-start;
  top: 0;
}

/*  to remove: fixes bug introduced in grapes v0.156 */
/*  remove it once the bug has been fixed on grapes side */
.AccountPayableSuppliersSubList table thead tr th:first-of-type span {
  justify-content: center;
}

.AccountPayableSuppliersSubList table thead tr th:nth-of-type(2) {
  overflow: visible;
}

.AccountPayableSuppliersSubList table tbody tr td:first-of-type div {
  justify-content: center;
}
/*  -- to remove */
