.Tag {
  position: relative;
  display: block;
  cursor: pointer;
  user-select: none;
}
.Tag.is-disabled {
  cursor: not-allowed;
}
.Tag.is-disabled .Tag-content {
  padding: 3px var(--unit-8);
  text-align: center;
}
.Tag.is-disabled .Tag-content::before,
.Tag.is-disabled .Tag-content::after {
  content: none;
}
.Tag .Tag-content:hover {
  background-color: var(--color-background-complementary-default);
  border: 1px solid var(--color-border-hover);
  color: var(--color-content-complementary);
}
.Tag .Tag-content:hover::after {
  background-color: var(--color-background-complementary-default);
}
.Tag .Tag-content:hover.has-icon svg path {
  fill: var(--color-content-complementary);
}
.Tag .Tag-content {
  position: relative;
  padding: 3px var(--unit-8);
  overflow: hidden;
  z-index: 1;
  border-radius: 2px;
  background-color: var(--color-background-secondary-default);
  color: var(--color-content-primary);
  border: 1px solid var(--color-border-default);
  font-size: 12px;
  font-weight: var(--fw-semibold);
  line-height: 15px;
  transition: background-color 150ms ease;
}
.Tag .Tag-content.has-icon {
  padding: 3px 27px 3px var(--unit-8);
}
.Tag .Tag-content.has-icon::after {
  content: '';
}
.Tag .Tag-content svg {
  position: absolute;
  z-index: 2;
  top: var(--unit-4);
  right: var(--unit-4);
  scale: 0.8;
}
.Tag .Tag-content::after {
  content: none;
  position: absolute;
  width: 15px;
  height: 100%;
  top: 0;
  right: 0;
  background-color: var(--color-background-secondary-default);
  transition: background-color 150ms ease;
}
.Tag.info .Tag-content:hover {
  background-color: var(--color-background-secondary-info-hover);
  border: 1px solid transparent;
  color: var(--color-content-info-default);
}
.Tag.info .Tag-content:hover:after {
  background-color: var(--color-background-secondary-info-hover);
}
.Tag.info .Tag-content {
  background-color: var(--color-background-secondary-info-default);
  border: 1px solid var(--color-border-info);
  color: var(--color-content-info-default);
}
.Tag.info .Tag-content::after {
  background-color: var(--color-background-secondary-info-default);
}
.Tag.info .Tag-content.has-icon svg path {
  fill: var(--color-content-info-default) !important;
}
.Tag.success .Tag-content:hover {
  background-color: var(--color-background-secondary-success-hover);
  border: 1px solid transparent;
  color: var(--color-content-success-default);
}
.Tag.success .Tag-content:hover::after {
  background-color: var(--color-background-secondary-success-hover);
}
.Tag.success .Tag-content {
  background-color: var(--color-background-secondary-success-default);
  border: 1px solid var(--color-border-success);
  color: var(--color-content-success-default);
}
.Tag.success .Tag-content::after {
  background-color: var(--color-background-secondary-success-default);
}
.Tag.success .Tag-content.has-icon svg path {
  fill: var(--color-content-success-default) !important;
}
