.UnexpectedErrorContainer {
  width: 100%;
  height: 100%;
  padding: var(--unit-20) 0;
  background-color: var(--color-background-primary-default);
}

.UnexpectedErrorContainer .UnexpectedError {
  max-width: 500px;
}
