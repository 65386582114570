@font-face {
  font-family: 'GT-America';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src:
    url('/static/fonts/GT-America/GT-America-Standard-Regular.woff2')
      format('woff2'),
    url('/static/fonts/GT-America/GT-America-Standard-Regular.woff')
      format('woff');
}

@font-face {
  font-family: 'GT-America';
  font-weight: normal;
  font-style: italic;
  font-display: swap;
  src:
    url('/static/fonts/GT-America/GT-America-Standard-Regular-Italic.woff2')
      format('woff2'),
    url('/static/fonts/GT-America/GT-America-Standard-Regular-Italic.woff')
      format('woff');
}

@font-face {
  font-family: 'GT-America';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src:
    url('/static/fonts/GT-America/GT-America-Standard-Medium.woff2')
      format('woff2'),
    url('/static/fonts/GT-America/GT-America-Standard-Medium.woff')
      format('woff');
}

@font-face {
  font-family: 'GT-America';
  font-weight: 500;
  font-style: italic;
  font-display: swap;
  src:
    url('/static/fonts/GT-America/GT-America-Standard-Medium-Italic.woff2')
      format('woff2'),
    url('/static/fonts/GT-America/GT-America-Standard-Medium-Italic.woff')
      format('woff');
}

@font-face {
  font-family: 'OCR A W00 Extended';
  src:
    url('/static/fonts/OCR/OCR.woff2') format('woff2'),
    url('/static/fonts/OCR/OCR.woff') format('woff');
}

@font-face {
  font-family: 'Roobert';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url('/static/fonts/Roobert/Roobert-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-display: swap;
  font-weight: 1 999;
  src: url('/static/fonts/Inter/Inter.woff2') format('woff2');
}
