.PlasticCard {
  background-color: var(--color-background-primary-default);
  flex-grow: 1;
}

.PlasticCard__container {
  width: 980px;
  margin: var(--unit-48) auto;
}

@media (max-width: 1024px) {
  .PlasticCard__container {
    width: initial;
    min-width: initial;
    margin: var(--unit-16);
  }
}
