.ClosedDoorContainer {
  height: 100%;
  overflow: auto hidden;
}

.ContentBlock {
  padding: var(--unit-40) var(--unit-32) var(--unit-56) var(--unit-32);
  gap: var(--unit-56);
  color: var(--color-content-primary);
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.MultiHubButton {
  background-color: #48465e66;
}

.ClosedDoor {
  width: 100%;
  min-width: fit-content;
}

.InvoiceRow {
  border-bottom: 1px solid var(--color-border-default);
}
