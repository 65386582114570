:root {
  --animation-delay: 0.8s;
}

.fakeCheckbox {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  background-color: var(--color-background-primary-default);
  border: 1px solid var(--color-border-default);
  position: absolute;
  top: 3px;
  left: calc(-1 * var(--unit-20));
}

.fakeCheckboxImg {
  opacity: 0;
  position: relative;
  top: -2px;
  left: -1px;
  transition: opacity 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

.animatedChecklist > li:nth-child(1) .fakeCheckboxImg {
  --index: 0;
}
.animatedChecklist > li:nth-child(2) .fakeCheckboxImg {
  --index: 1;
}
.animatedChecklist > li:nth-child(3) .fakeCheckboxImg {
  --index: 2;
}
.animatedChecklist > li:nth-child(4) .fakeCheckboxImg {
  --index: 3;
}
.animatedChecklist > li:nth-child(5) .fakeCheckboxImg {
  --index: 4;
}
.animatedChecklist > li:nth-child(6) .fakeCheckboxImg {
  --index: 5;
}
.animatedChecklist > li .fakeCheckboxImg {
  opacity: 1;
  transition-delay: calc(var(--index, 0) * var(--animation-delay));
}

.replayButton {
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
}
