.EditControlRuleDetails__content__form__row {
  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--unit-16);
  width: 100%;
}

.EditControlRuleDetails__content__form__row.EditControlRuleDetails__content__form__row--double {
  margin-top: var(--unit-16);
  grid-template-columns: 1fr 1fr;
}

.EditControlRuleDetails__error {
  margin-top: var(--unit-8);
}

.EditControlRuleDetails__content__form__row__day-dropdow {
  position: relative;
}
