.midPageTitle {
  margin-top: var(--unit-24);
}

.noSuppliersCallout {
  margin-bottom: var(--unit-16);
}

.placeholder {
  color: var(--color-content-secondary-bg-secondary);
  font-style: normal;
}

.formCallout {
  margin-top: var(--unit-8);
  margin-bottom: var(--unit-16);
}

.defaultForm__inputWrapper {
  display: flex;
  flex-direction: row;
}

.defaultForm__input:first-child {
  margin-right: var(--unit-8);
}

.defaultForm__input:last-child {
  margin-left: var(--unit-8);
}

.defaultForm__input {
  margin-top: var(--unit-24);
  flex: 1 1 50%;
}

.defaultForm__error {
  margin-top: var(--unit-8);
}

.defaultForm__submit {
  margin-top: var(--unit-24);
  margin-right: var(--unit-16);
}
