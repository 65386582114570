.wrapper {
  box-sizing: border-box;
}

.titleLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: var(--unit-8);
  color: var(--color-content-primary);
}

.titleLine > img {
  margin-right: var(--unit-16);
  width: var(--unit-32);
  height: var(--unit-32);
  border-radius: var(--border-radius-4);
}

.title {
  color: var(--color-content-primary);
  padding-right: var(--unit-8);
}

.lastRefreshedAt {
  margin-left: auto;
  display: flex;
  align-items: center;
  color: var(--color-content-primary);
}

.defaultForTitle {
  color: var(--color-content-primary);
  margin-bottom: var(--unit-8);
}

.supplierSelectFormField {
  padding: var(--unit-16);
  margin: var(--unit-16);
  margin-left: calc(-1 * var(--unit-8));
  margin-right: calc(-1 * var(--unit-8));
  background-color: var(--color-background-secondary-default);
  border-radius: var(--border-radius-4);
}

.settingsErrorCallout {
  margin-bottom: var(--unit-16);
}

.connectionErrorCallout {
  margin-bottom: var(--unit-24);
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
}

.actionsSpace {
  flex: 1;
}

.switchInput {
  margin-bottom: var(--unit-24);
  margin-top: var(--unit-8);
}

.fileBasedTitle {
  padding-bottom: var(--unit-8);
}

.oAuth1ErrorList {
  padding-left: var(--unit-24);
  margin-bottom: var(--unit-16);
}

.oAuth1ErrorList li {
  list-style-type: disc;
  color: var(--color-content-primary);
  line-height: var(--unit-24);
}
