.CheckBankStatementForm {
  display: flex;
  justify-content: space-between;
}

.CheckBankStatementForm__form {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 340px;
}

.CheckBankStatementForm__form__description {
  font-size: var(--font-size-14);
}

.CheckBankStatementForm__form__description:last-of-type {
  margin-top: var(--unit-24);
  margin-bottom: var(--unit-32);
}

.CheckBankStatementForm__form__inputs {
  display: flex;
  gap: var(--unit-8);
}

.CheckBankStatementForm__form__error {
  margin-top: var(--unit-24);
}

.CheckBankStatementForm__form__error button {
  font-size: inherit;
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: 0;
  background: none;
  color: inherit;
  font-weight: var(--fw-semibold);
  cursor: pointer;
}
.CheckBankStatementForm__form__error button:hover {
  text-decoration: underline;
}

.CheckBankStatementForm__form__actions {
  display: flex;
  gap: var(--unit-8);
  margin-top: var(--unit-40);
}
.CheckBankStatementForm__form__actions_update {
  margin-top: var(--unit-16);
}

.CheckBankStatementForm__illustration {
  position: relative;
  margin-left: var(--unit-40);
  margin-top: -34px;
}

.CheckBankStatementForm__illustration--in-wallet {
  margin-top: var(--unit-4);
}

.CheckBankStatementForm__illustration__bankIcon {
  position: absolute;
  top: var(--unit-40);
  left: var(--unit-32);
  display: flex;
  transform: rotate(-5deg);
}

.CheckBankStatementForm__illustration__bankIcon__title {
  margin-left: var(--unit-4);
  font-size: 8px;
  line-height: 1;
  color: var(--color-content-primary);
}
