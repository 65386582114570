:root {
  --iti-flag-height: 15px;
  --iti-flag-width: 20px;
  --iti-path-flags-1x: url('/static/img/intlTelInput/flags.png');
  --iti-path-flags-2x: url('/static/img/intlTelInput/flags@2x.png');
  --iti-flag-sprite-width: 5630px;
  --iti-flag-sprite-height: 15px;
  --iti-flag-offset: 100px;
}

.iti-flag-container {
  width: var(--iti-flag-width);
}

.iti-flag {
  height: var(--iti-flag-height);
  width: var(--iti-flag-width);
  background-image: var(--iti-path-flags-1x);
  background-repeat: no-repeat;
  background-position: var(--iti-flag-offset) 0;
  background-size: var(--iti-flag-sprite-width) var(--iti-flag-sprite-height);
}

@media (min-resolution: 2x) {
  .iti-flag {
    background-image: var(--iti-path-flags-2x);
  }
}

.iti-flag.ac {
  --iti-flag-height: 10px;
  --iti-flag-offset: 0px;
}
.iti-flag.ad {
  --iti-flag-height: 14px;
  --iti-flag-offset: -22px;
}
.iti-flag.ae {
  --iti-flag-height: 10px;
  --iti-flag-offset: -44px;
}
.iti-flag.af {
  --iti-flag-height: 14px;
  --iti-flag-offset: -66px;
}
.iti-flag.ag {
  --iti-flag-height: 14px;
  --iti-flag-offset: -88px;
}
.iti-flag.ai {
  --iti-flag-height: 10px;
  --iti-flag-offset: -110px;
}
.iti-flag.al {
  --iti-flag-offset: -132px;
}
.iti-flag.am {
  --iti-flag-height: 10px;
  --iti-flag-offset: -154px;
}
.iti-flag.ao {
  --iti-flag-height: 14px;
  --iti-flag-offset: -176px;
}
.iti-flag.aq {
  --iti-flag-height: 14px;
  --iti-flag-offset: -198px;
}
.iti-flag.ar {
  --iti-flag-height: 13px;
  --iti-flag-offset: -220px;
}
.iti-flag.as {
  --iti-flag-height: 10px;
  --iti-flag-offset: -242px;
}
.iti-flag.at {
  --iti-flag-height: 14px;
  --iti-flag-offset: -264px;
}
.iti-flag.au {
  --iti-flag-height: 10px;
  --iti-flag-offset: -286px;
}
.iti-flag.aw {
  --iti-flag-height: 14px;
  --iti-flag-offset: -308px;
}
.iti-flag.ax {
  --iti-flag-height: 13px;
  --iti-flag-offset: -330px;
}
.iti-flag.az {
  --iti-flag-height: 10px;
  --iti-flag-offset: -352px;
}
.iti-flag.ba {
  --iti-flag-height: 10px;
  --iti-flag-offset: -374px;
}
.iti-flag.bb {
  --iti-flag-height: 14px;
  --iti-flag-offset: -396px;
}
.iti-flag.bd {
  --iti-flag-height: 12px;
  --iti-flag-offset: -418px;
}
.iti-flag.be {
  --iti-flag-width: 18px;
  --iti-flag-offset: -440px;
}
.iti-flag.bf {
  --iti-flag-height: 14px;
  --iti-flag-offset: -460px;
}
.iti-flag.bg {
  --iti-flag-height: 12px;
  --iti-flag-offset: -482px;
}
.iti-flag.bh {
  --iti-flag-height: 12px;
  --iti-flag-offset: -504px;
}
.iti-flag.bi {
  --iti-flag-height: 12px;
  --iti-flag-offset: -526px;
}
.iti-flag.bj {
  --iti-flag-height: 14px;
  --iti-flag-offset: -548px;
}
.iti-flag.bl {
  --iti-flag-height: 14px;
  --iti-flag-offset: -570px;
}
.iti-flag.bm {
  --iti-flag-height: 10px;
  --iti-flag-offset: -592px;
}
.iti-flag.bn {
  --iti-flag-height: 10px;
  --iti-flag-offset: -614px;
}
.iti-flag.bo {
  --iti-flag-height: 14px;
  --iti-flag-offset: -636px;
}
.iti-flag.bq {
  --iti-flag-height: 14px;
  --iti-flag-offset: -658px;
}
.iti-flag.br {
  --iti-flag-height: 14px;
  --iti-flag-offset: -680px;
}
.iti-flag.bs {
  --iti-flag-height: 10px;
  --iti-flag-offset: -702px;
}
.iti-flag.bt {
  --iti-flag-height: 14px;
  --iti-flag-offset: -724px;
}
.iti-flag.bv {
  --iti-flag-offset: -746px;
}
.iti-flag.bw {
  --iti-flag-height: 14px;
  --iti-flag-offset: -768px;
}
.iti-flag.by {
  --iti-flag-height: 10px;
  --iti-flag-offset: -790px;
}
.iti-flag.bz {
  --iti-flag-height: 14px;
  --iti-flag-offset: -812px;
}
.iti-flag.ca {
  --iti-flag-height: 10px;
  --iti-flag-offset: -834px;
}
.iti-flag.cc {
  --iti-flag-height: 10px;
  --iti-flag-offset: -856px;
}
.iti-flag.cd {
  --iti-flag-offset: -878px;
}
.iti-flag.cf {
  --iti-flag-height: 14px;
  --iti-flag-offset: -900px;
}
.iti-flag.cg {
  --iti-flag-height: 14px;
  --iti-flag-offset: -922px;
}
.iti-flag.ch {
  --iti-flag-width: 15px;
  --iti-flag-offset: -944px;
}
.iti-flag.ci {
  --iti-flag-height: 14px;
  --iti-flag-offset: -961px;
}
.iti-flag.ck {
  --iti-flag-height: 10px;
  --iti-flag-offset: -983px;
}
.iti-flag.cl {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1005px;
}
.iti-flag.cm {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1027px;
}
.iti-flag.cn {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1049px;
}
.iti-flag.co {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1071px;
}
.iti-flag.cp {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1093px;
}
.iti-flag.cr {
  --iti-flag-height: 12px;
  --iti-flag-offset: -1115px;
}
.iti-flag.cu {
  --iti-flag-height: 10px;
  --iti-flag-offset: -1137px;
}
.iti-flag.cv {
  --iti-flag-height: 12px;
  --iti-flag-offset: -1159px;
}
.iti-flag.cw {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1181px;
}
.iti-flag.cx {
  --iti-flag-height: 10px;
  --iti-flag-offset: -1203px;
}
.iti-flag.cy {
  --iti-flag-height: 13px;
  --iti-flag-offset: -1225px;
}
.iti-flag.cz {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1247px;
}
.iti-flag.de {
  --iti-flag-height: 12px;
  --iti-flag-offset: -1269px;
}
.iti-flag.dg {
  --iti-flag-height: 10px;
  --iti-flag-offset: -1291px;
}
.iti-flag.dj {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1313px;
}
.iti-flag.dk {
  --iti-flag-offset: -1335px;
}
.iti-flag.dm {
  --iti-flag-height: 10px;
  --iti-flag-offset: -1357px;
}
.iti-flag.do {
  --iti-flag-height: 13px;
  --iti-flag-offset: -1379px;
}
.iti-flag.dz {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1401px;
}
.iti-flag.ea {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1423px;
}
.iti-flag.ec {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1445px;
}
.iti-flag.ee {
  --iti-flag-height: 13px;
  --iti-flag-offset: -1467px;
}
.iti-flag.eg {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1489px;
}
.iti-flag.eh {
  --iti-flag-height: 10px;
  --iti-flag-offset: -1511px;
}
.iti-flag.er {
  --iti-flag-height: 10px;
  --iti-flag-offset: -1533px;
}
.iti-flag.es {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1555px;
}
.iti-flag.et {
  --iti-flag-height: 10px;
  --iti-flag-offset: -1577px;
}
.iti-flag.eu {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1599px;
}
.iti-flag.fi {
  --iti-flag-height: 12px;
  --iti-flag-offset: -1621px;
}
.iti-flag.fj {
  --iti-flag-height: 10px;
  --iti-flag-offset: -1643px;
}
.iti-flag.fk {
  --iti-flag-height: 10px;
  --iti-flag-offset: -1665px;
}
.iti-flag.fm {
  --iti-flag-height: 11px;
  --iti-flag-offset: -1687px;
}
.iti-flag.fo {
  --iti-flag-offset: -1709px;
}
.iti-flag.fr {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1731px;
}
.iti-flag.ga {
  --iti-flag-offset: -1753px;
}
.iti-flag.gb {
  --iti-flag-height: 10px;
  --iti-flag-offset: -1775px;
}
.iti-flag.gd {
  --iti-flag-height: 12px;
  --iti-flag-offset: -1797px;
}
.iti-flag.ge {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1819px;
}
.iti-flag.gf {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1841px;
}
.iti-flag.gg {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1863px;
}
.iti-flag.gh {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1885px;
}
.iti-flag.gi {
  --iti-flag-height: 10px;
  --iti-flag-offset: -1907px;
}
.iti-flag.gl {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1929px;
}
.iti-flag.gm {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1951px;
}
.iti-flag.gn {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1973px;
}
.iti-flag.gp {
  --iti-flag-height: 14px;
  --iti-flag-offset: -1995px;
}
.iti-flag.gq {
  --iti-flag-height: 14px;
  --iti-flag-offset: -2017px;
}
.iti-flag.gr {
  --iti-flag-height: 14px;
  --iti-flag-offset: -2039px;
}
.iti-flag.gs {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2061px;
}
.iti-flag.gt {
  --iti-flag-height: 13px;
  --iti-flag-offset: -2083px;
}
.iti-flag.gu {
  --iti-flag-height: 11px;
  --iti-flag-offset: -2105px;
}
.iti-flag.gw {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2127px;
}
.iti-flag.gy {
  --iti-flag-height: 12px;
  --iti-flag-offset: -2149px;
}
.iti-flag.hk {
  --iti-flag-height: 14px;
  --iti-flag-offset: -2171px;
}
.iti-flag.hm {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2193px;
}
.iti-flag.hn {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2215px;
}
.iti-flag.hr {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2237px;
}
.iti-flag.ht {
  --iti-flag-height: 12px;
  --iti-flag-offset: -2259px;
}
.iti-flag.hu {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2281px;
}
.iti-flag.ic {
  --iti-flag-height: 14px;
  --iti-flag-offset: -2303px;
}
.iti-flag.id {
  --iti-flag-height: 14px;
  --iti-flag-offset: -2325px;
}
.iti-flag.ie {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2347px;
}
.iti-flag.il {
  --iti-flag-offset: -2369px;
}
.iti-flag.im {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2391px;
}
.iti-flag.in {
  --iti-flag-height: 14px;
  --iti-flag-offset: -2413px;
}
.iti-flag.io {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2435px;
}
.iti-flag.iq {
  --iti-flag-height: 14px;
  --iti-flag-offset: -2457px;
}
.iti-flag.ir {
  --iti-flag-height: 12px;
  --iti-flag-offset: -2479px;
}
.iti-flag.is {
  --iti-flag-offset: -2501px;
}
.iti-flag.it {
  --iti-flag-height: 14px;
  --iti-flag-offset: -2523px;
}
.iti-flag.je {
  --iti-flag-height: 12px;
  --iti-flag-offset: -2545px;
}
.iti-flag.jm {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2567px;
}
.iti-flag.jo {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2589px;
}
.iti-flag.jp {
  --iti-flag-height: 14px;
  --iti-flag-offset: -2611px;
}
.iti-flag.ke {
  --iti-flag-height: 14px;
  --iti-flag-offset: -2633px;
}
.iti-flag.kg {
  --iti-flag-height: 12px;
  --iti-flag-offset: -2655px;
}
.iti-flag.kh {
  --iti-flag-height: 13px;
  --iti-flag-offset: -2677px;
}
.iti-flag.ki {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2699px;
}
.iti-flag.km {
  --iti-flag-height: 12px;
  --iti-flag-offset: -2721px;
}
.iti-flag.kn {
  --iti-flag-height: 14px;
  --iti-flag-offset: -2743px;
}
.iti-flag.kp {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2765px;
}
.iti-flag.kr {
  --iti-flag-height: 14px;
  --iti-flag-offset: -2787px;
}
.iti-flag.kw {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2809px;
}
.iti-flag.ky {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2831px;
}
.iti-flag.kz {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2853px;
}
.iti-flag.la {
  --iti-flag-height: 14px;
  --iti-flag-offset: -2875px;
}
.iti-flag.lb {
  --iti-flag-height: 14px;
  --iti-flag-offset: -2897px;
}
.iti-flag.lc {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2919px;
}
.iti-flag.li {
  --iti-flag-height: 12px;
  --iti-flag-offset: -2941px;
}
.iti-flag.lk {
  --iti-flag-height: 10px;
  --iti-flag-offset: -2963px;
}
.iti-flag.lr {
  --iti-flag-height: 11px;
  --iti-flag-offset: -2985px;
}
.iti-flag.ls {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3007px;
}
.iti-flag.lt {
  --iti-flag-height: 12px;
  --iti-flag-offset: -3029px;
}
.iti-flag.lu {
  --iti-flag-height: 12px;
  --iti-flag-offset: -3051px;
}
.iti-flag.lv {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3073px;
}
.iti-flag.ly {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3095px;
}
.iti-flag.ma {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3117px;
}
.iti-flag.mc {
  --iti-flag-width: 19px;
  --iti-flag-offset: -3139px;
}
.iti-flag.md {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3160px;
}
.iti-flag.me {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3182px;
}
.iti-flag.mf {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3204px;
}
.iti-flag.mg {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3226px;
}
.iti-flag.mh {
  --iti-flag-height: 11px;
  --iti-flag-offset: -3248px;
}
.iti-flag.mk {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3270px;
}
.iti-flag.ml {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3292px;
}
.iti-flag.mm {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3314px;
}
.iti-flag.mn {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3336px;
}
.iti-flag.mo {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3358px;
}
.iti-flag.mp {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3380px;
}
.iti-flag.mq {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3402px;
}
.iti-flag.mr {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3424px;
}
.iti-flag.ms {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3446px;
}
.iti-flag.mt {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3468px;
}
.iti-flag.mu {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3490px;
}
.iti-flag.mv {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3512px;
}
.iti-flag.mw {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3534px;
}
.iti-flag.mx {
  --iti-flag-height: 12px;
  --iti-flag-offset: -3556px;
}
.iti-flag.my {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3578px;
}
.iti-flag.mz {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3600px;
}
.iti-flag.na {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3622px;
}
.iti-flag.nc {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3644px;
}
.iti-flag.ne {
  --iti-flag-width: 18px;
  --iti-flag-offset: -3666px;
}
.iti-flag.nf {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3686px;
}
.iti-flag.ng {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3708px;
}
.iti-flag.ni {
  --iti-flag-height: 12px;
  --iti-flag-offset: -3730px;
}
.iti-flag.nl {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3752px;
}
.iti-flag.no {
  --iti-flag-offset: -3774px;
}
.iti-flag.np {
  --iti-flag-width: 13px;
  --iti-flag-offset: -3796px;
}
.iti-flag.nr {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3811px;
}
.iti-flag.nu {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3833px;
}
.iti-flag.nz {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3855px;
}
.iti-flag.om {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3877px;
}
.iti-flag.pa {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3899px;
}
.iti-flag.pe {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3921px;
}
.iti-flag.pf {
  --iti-flag-height: 14px;
  --iti-flag-offset: -3943px;
}
.iti-flag.pg {
  --iti-flag-offset: -3965px;
}
.iti-flag.ph {
  --iti-flag-height: 10px;
  --iti-flag-offset: -3987px;
}
.iti-flag.pk {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4009px;
}
.iti-flag.pl {
  --iti-flag-height: 13px;
  --iti-flag-offset: -4031px;
}
.iti-flag.pm {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4053px;
}
.iti-flag.pn {
  --iti-flag-height: 10px;
  --iti-flag-offset: -4075px;
}
.iti-flag.pr {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4097px;
}
.iti-flag.ps {
  --iti-flag-height: 10px;
  --iti-flag-offset: -4119px;
}
.iti-flag.pt {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4141px;
}
.iti-flag.pw {
  --iti-flag-height: 13px;
  --iti-flag-offset: -4163px;
}
.iti-flag.py {
  --iti-flag-height: 11px;
  --iti-flag-offset: -4185px;
}
.iti-flag.qa {
  --iti-flag-height: 8px;
  --iti-flag-offset: -4207px;
}
.iti-flag.re {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4229px;
}
.iti-flag.ro {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4251px;
}
.iti-flag.rs {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4273px;
}
.iti-flag.ru {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4295px;
}
.iti-flag.rw {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4317px;
}
.iti-flag.sa {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4339px;
}
.iti-flag.sb {
  --iti-flag-height: 10px;
  --iti-flag-offset: -4361px;
}
.iti-flag.sc {
  --iti-flag-height: 10px;
  --iti-flag-offset: -4383px;
}
.iti-flag.sd {
  --iti-flag-height: 10px;
  --iti-flag-offset: -4405px;
}
.iti-flag.se {
  --iti-flag-height: 13px;
  --iti-flag-offset: -4427px;
}
.iti-flag.sg {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4449px;
}
.iti-flag.sh {
  --iti-flag-height: 10px;
  --iti-flag-offset: -4471px;
}
.iti-flag.si {
  --iti-flag-height: 10px;
  --iti-flag-offset: -4493px;
}
.iti-flag.sj {
  --iti-flag-offset: -4515px;
}
.iti-flag.sk {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4537px;
}
.iti-flag.sl {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4559px;
}
.iti-flag.sm {
  --iti-flag-offset: -4581px;
}
.iti-flag.sn {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4603px;
}
.iti-flag.so {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4625px;
}
.iti-flag.sr {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4647px;
}
.iti-flag.ss {
  --iti-flag-height: 10px;
  --iti-flag-offset: -4669px;
}
.iti-flag.st {
  --iti-flag-height: 10px;
  --iti-flag-offset: -4691px;
}
.iti-flag.sv {
  --iti-flag-height: 12px;
  --iti-flag-offset: -4713px;
}
.iti-flag.sx {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4735px;
}
.iti-flag.sy {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4757px;
}
.iti-flag.sz {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4779px;
}
.iti-flag.ta {
  --iti-flag-height: 10px;
  --iti-flag-offset: -4801px;
}
.iti-flag.tc {
  --iti-flag-height: 10px;
  --iti-flag-offset: -4823px;
}
.iti-flag.td {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4845px;
}
.iti-flag.tf {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4867px;
}
.iti-flag.tg {
  --iti-flag-height: 13px;
  --iti-flag-offset: -4889px;
}
.iti-flag.th {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4911px;
}
.iti-flag.tj {
  --iti-flag-height: 10px;
  --iti-flag-offset: -4933px;
}
.iti-flag.tk {
  --iti-flag-height: 10px;
  --iti-flag-offset: -4955px;
}
.iti-flag.tl {
  --iti-flag-height: 10px;
  --iti-flag-offset: -4977px;
}
.iti-flag.tm {
  --iti-flag-height: 14px;
  --iti-flag-offset: -4999px;
}
.iti-flag.tn {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5021px;
}
.iti-flag.to {
  --iti-flag-height: 10px;
  --iti-flag-offset: -5043px;
}
.iti-flag.tr {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5065px;
}
.iti-flag.tt {
  --iti-flag-height: 12px;
  --iti-flag-offset: -5087px;
}
.iti-flag.tv {
  --iti-flag-height: 10px;
  --iti-flag-offset: -5109px;
}
.iti-flag.tw {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5131px;
}
.iti-flag.tz {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5153px;
}
.iti-flag.ua {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5175px;
}
.iti-flag.ug {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5197px;
}
.iti-flag.um {
  --iti-flag-height: 11px;
  --iti-flag-offset: -5219px;
}
.iti-flag.us {
  --iti-flag-height: 11px;
  --iti-flag-offset: -5241px;
}
.iti-flag.uy {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5263px;
}
.iti-flag.uz {
  --iti-flag-height: 10px;
  --iti-flag-offset: -5285px;
}
.iti-flag.va {
  --iti-flag-width: 15px;
  --iti-flag-offset: -5307px;
}
.iti-flag.vc {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5324px;
}
.iti-flag.ve {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5346px;
}
.iti-flag.vg {
  --iti-flag-height: 10px;
  --iti-flag-offset: -5368px;
}
.iti-flag.vi {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5390px;
}
.iti-flag.vn {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5412px;
}
.iti-flag.vu {
  --iti-flag-height: 12px;
  --iti-flag-offset: -5434px;
}
.iti-flag.wf {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5456px;
}
.iti-flag.ws {
  --iti-flag-height: 10px;
  --iti-flag-offset: -5478px;
}
.iti-flag.xk {
  --iti-flag-offset: -5500px;
}
.iti-flag.ye {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5522px;
}
.iti-flag.yt {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5544px;
}
.iti-flag.za {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5566px;
}
.iti-flag.zm {
  --iti-flag-height: 14px;
  --iti-flag-offset: -5588px;
}
.iti-flag.zw {
  --iti-flag-height: 10px;
  --iti-flag-offset: -5610px;
}
