@container (width > 1280px) {
  *[id^='meh__portal-add-funds-button--'] {
    display: none;
  }
}

@container (width <= 1280px) {
  *[id^='meh__portal-add-funds-button--'] {
    display: block;
  }
}
