.ScheduledPaymentsByUserListSkeleton__table-header__title {
  margin-left: var(--unit-16);
}

.ScheduledPaymentsByUserListSkeleton__table-cell {
  display: grid;
  grid-template-columns: min-content min-content 1fr var(--unit-64);
  align-items: center;
  gap: var(--unit-16);
  width: 100%;
}

.ScheduledPaymentsByUserListSkeleton__table-cell:last-child {
  justify-content: right;
}

.ScheduledPaymentsByUserListSkeleton__table-cell__description {
  display: grid;
  grid-template-rows: repeat(2, min-content);
  gap: var(--unit-4);
  align-items: center;
}
