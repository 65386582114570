.Popover {
  box-shadow: var(--box-shadow-40);
  background: var(--color-background-primary-brand-default);
  border-radius: var(--border-radius-8);
  color: var(--color-content-complementary);
  max-width: 415px;
  position: relative;
}

.Popover::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: absolute;
  top: -5px;
  left: var(--unit-20);
}

.Popover__footer {
  margin-top: var(--unit-24);
  text-align: right;
}

.Popover__footer button:nth-child(2) {
  margin-left: var(--unit-16);
}

.Popover__close-button {
  position: absolute;
  top: var(--unit-8);
  right: var(--unit-8);
  color: var(--color-content-complementary);
}
