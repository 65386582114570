.NotificationSlackSetting {
  text-align: left;
  display: flex;
  align-items: center;
  padding: var(--unit-16) 0;
}

.NotificationSlackSetting--slack-enabled {
  flex-direction: column;
  align-items: flex-start;
}

.NotificationSlackSetting__description {
  color: var(--color-content-primary);
  line-height: 1.3;
  margin-top: var(--unit-4);
}
