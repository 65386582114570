.InputTagsSelect {
  max-width: 100%;
  display: inline-block;
  position: relative;
}
.InputTagsSelect__selector {
  color: var(--color-content-primary);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-4);
  height: var(--unit-48);
  padding-top: 0;
  padding-left: 15px;
}
.InputTagsSelect__selector:focus-within {
  border: 1px solid var(--color-border-default);
}
.InputTagsSelect__selector > span {
  display: flex;
  align-items: center;
  height: 100%;
}
.InputTagsSelect__selector input {
  margin: 0;
  flex: 1;
}
.InputTagsSelect__selector input:focus {
  color: var(--color-content-primary) !important;
}
.InputTagsSelect__selector input::placeholder {
  color: var(--color-content-secondary-bg-secondary);
  font-size: 15px;
}
.InputTagsSelect__selector .Tag {
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
}
.InputTagsSelect__selector .react-tagsinput-input {
  font-size: var(--font-size-14);
  font-family: var(--ff-base);
  padding: 0;
}
.InputTagsSelect__choices {
  position: absolute;
  min-width: 200px;
}
.InputTagsSelect__choices .InputTagsSelect__choices-item {
  align-items: center;
  justify-content: space-between;
}
.InputTagsSelect__choices,
.InputTagsSelect__subchoices {
  z-index: 10;
  border-radius: var(--border-radius-4);
  background-color: var(--color-background-primary-default);
  box-shadow: var(--elevation-l);
  border: 1px solid var(--color-border-default);
  max-height: 175px;
  overflow-y: auto;
  box-sizing: border-box;
  margin-top: var(--unit-8);
  padding: var(--unit-4) 0;
}
.InputTagsSelect__subchoices {
  position: absolute;
  left: 200px;
  margin-left: var(--unit-4);
  display: inline-block;
}
.InputTagsSelect__choices-item,
.InputTagsSelect__subchoices-item {
  display: flex;
  position: relative;
  width: 100%;
  height: 37px;
  padding: 6px 15px;
  border-radius: 2px;
  color: var(--color-content-primary);
  font-family: var(--ff-base);
  font-size: 15px;
  font-weight: var(--fw-medium);
  box-sizing: border-box;
  text-align: left;
  text-decoration: none;
  outline: none;
  border: none;
  background: none;
}
.InputTagsSelect__choices-item:hover,
.InputTagsSelect__subchoices-item:hover {
  background-color: var(--color-background-primary-hover);
  cursor: pointer;
}
