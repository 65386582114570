.Counterparty {
  display: flex;
  align-items: center;
}

.Counterparty__information {
  margin-left: var(--unit-8);
}

.Counterparty__label {
  color: var(--color-content-primary);
}

.Counterparty__sublabel {
  color: var(--color-content-secondary-bg-primary);
}

.Counterparty__sublabel--invalid {
  color: var(--color-content-alert-default);
}
