.BetaFeatureNotice__give-feedback {
  color: var(--color-content-brand-default);
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
}

.BetaFeatureNotice__give-feedback:hover {
  background: none;
}
