.WarningRow {
  color: var(--color-content-primary);
  margin-top: var(--unit-8);
  padding: var(--unit-8);
  border-radius: var(--border-radius-4);
  background-color: var(--color-background-secondary-default);
  display: flex;
  align-items: center;
  gap: var(--unit-8);
}
