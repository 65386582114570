.AccountingBaseSwitchSummaryModal__content {
  text-align: left;
  background-color: var(--color-background-secondary-default);
  border-radius: var(--border-radius-8);
  margin-top: var(--unit-24);
  padding: var(--unit-24);
}

.AccountingBaseSwitchSummaryModal__content--with-callout {
  margin-top: var(--unit-16);
}

.AccountingBaseSwitchSummaryModal__content a {
  color: var(--color-content-primary);
  text-decoration: underline;
  color: var(--color-content-primary);
}

.AccountingBaseSwitchSummaryModal__title {
  color: var(--color-content-primary);
  display: flex;
  align-items: center;
}

.AccountingBaseSwitchSummaryModal__list {
  margin-top: var(--unit-16);
  padding-left: var(--unit-24);
}

.AccountingBaseSwitchSummaryModal__list li {
  list-style-type: disc;
  color: var(--color-content-primary);
}

.AccountingBaseSwitchSummaryModal__list-item {
  list-style-type: disc;
  color: var(--color-content-primary);
}
