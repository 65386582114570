.ScaWireTransferConfirmationModal__img__background {
  position: relative;
  background-color: var(--color-background-secondary-brand-default);
  width: 100%;
  height: 227px;
  display: flex;
  justify-content: center;
  box-shadow: 0px 1px 1px rgba(4, 2, 34, 0.04);
  border-radius: var(--border-radius-8);
  margin-top: var(--unit-24);
}

.ScaWireTransferConfirmationModal__img {
  position: absolute;
  bottom: 0;
}

.ScaWireTransferConfirmationModal__btn-alternative {
  margin-top: var(--unit-24);
}
