.ScaWireTransferActivationModal__content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.ScaWireTransferActivationModal__content__imgs {
  position: relative;
  background-color: var(--color-background-secondary-brand-default);
  width: 100%;
  height: 305px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 1px rgba(4, 2, 34, 0.04);
  border-radius: var(--border-radius-8);
  margin-top: var(--unit-24);
}

.ScaWireTransferActivationModal__content__imgs__scan-to-download {
  position: absolute;
  bottom: 138px;
  left: var(--unit-16);
}

.ScaWireTransferActivationModal__content__imgs__qr-code {
  width: 180px;
}

.ScaWireTransferActivationModal__content__imgs__badges {
  margin-top: var(--unit-24);
}

.ScaWireTransferActivationModal__content__imgs__carole-cooper {
  position: absolute;
  bottom: 0;
  right: var(--unit-4);
}

.ScaWireTransferActivationModal__content__error {
  margin-top: var(--unit-16);
}

.ScaWireTransferActivationModal__content__need-help {
  margin-top: var(--unit-24);
}
