.CreateAccountPayable {
  display: flex;
  flex: 1 1;
  gap: var(--unit-16);
}

.CreateAccountPayable__col {
  width: 100%;
}

.CreateAccountPayableError {
  margin-top: var(--unit-16);
}
