.PieChart {
  position: relative;
}

.PieChart__icon {
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
}
