.ToReview.page__container {
  height: calc(100% - var(--unit-40));
}

.ToReview__invoice-table {
  padding: 0 var(--unit-24);
  position: relative;
  display: grid;
  grid-template-columns: 344px minmax(344px, 600px) minmax(480px, 100%);
  gap: var(--unit-24);
  height: 100%;
  min-height: 0;
  background-color: var(--color-background-primary-default);
  overflow: hidden;
}
