.MissingReceiptsLoader__skeleton {
  background-color: var(--color-background-secondary-default);
  border-radius: 5px;
  height: 15px;
  width: 200px;
  margin: var(--unit-20) 0;
}

.MissingReceiptsLoader__skeleton.MissingReceiptsLoader__skeleton--long {
  width: 100%;
}
