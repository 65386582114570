.root {
  padding: var(--unit-40);
  overflow: auto;
  flex: 1;
  background-color: var(--color-background-primary-default);
  grid-area: main;
}

.container {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  gap: var(--unit-40);
  max-width: 1280px;
  margin: 0 auto;
}

@media (max-width: 1280px) {
  .root {
    padding: var(--unit-40) var(--unit-24);
  }
}
