/** Grapes hacking time: Make the header sticky **/
.listbox > li[role='presentation']:first-child {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: inherit;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom: 1px solid;
  border-color: inherit;
}

.listbox > li[role='presentation']:first-child + li[role='presentation'] > div {
  border-top: none;
}
