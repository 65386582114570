.select {
  width: 132px;
}

.select input,
.select ul {
  width: 91px;
}

.dropdown {
  width: 288px;
}

.dropdown div {
  width: 100%;
  text-align: left;
}

.callout {
  width: 272px;
}

.callout span {
  white-space: wrap;
}
