.ApprovalPreview__rule__from__text,
.ApprovalPreview__rule__upTo__text {
  color: var(--color-content-primary);
  font-weight: var(--fw-semibold);
}

.ApprovalPreview__rule__canApproveAll,
.ApprovalPreview__rule__from__value,
.ApprovalPreview__rule__upTo__value {
  font-weight: var(--fw-semibold);
}

.ApprovalPreview__rule__approver {
  display: flex;
  align-items: center;
  padding-top: var(--unit-8);
}

.ApprovalPreview__rule__approver__index {
  height: var(--unit-20);
  width: var(--unit-20);
  border-radius: var(--border-radius-4);
  color: var(--color-content-info-default);
  font-weight: var(--fw-semibold);
  background-color: var(--color-background-secondary-info-default);
  border: 1px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-right: var(--unit-12);
  font-size: var(--font-size-12);
}

.ApprovalPreview__rule i {
  --item-height: var(--unit-48);

  position: absolute;
  left: calc(var(--unit-8) + 1px);
  height: var(--item-height);
  border-left: 1px solid transparent;
}

.ApprovalPreview__rule i:first-of-type {
  top: var(--item-height);
  height: calc(var(--item-height) / 2);
}

.ApprovalPreview__rule i:last-of-type {
  height: calc(var(--item-height) / 2);
}

.ApprovalPreview__rule i:only-of-type {
  border-left: none;
}
