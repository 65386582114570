#button {
  width: 100%;
  height: var(--unit-40);
  border: none;
  border-radius: var(--border-radius-8);
  background-color: var(--color-background-primary-brand-default);
  color: var(--color-content-complementary);
  margin-bottom: var(--unit-24);
  box-shadow: var(--elevation-m);
  font: var(--body-m);
  padding-block: var(--unit-8);
  cursor: pointer;
  outline: none;
}

#button:focus-visible {
  outline: var(--focus-ring);
  outline-offset: var(--focus-ring-offset);
}
