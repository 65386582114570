.header {
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--unit-16);
}

.headerText {
  flex: 3;
}

.headerTextDescription {
  color: var(--color-content-secondary-bg-primary);
  margin-top: var(--unit-8);
}

.headerTextDescription > a {
  text-decoration: underline;
  color: var(--color-content-primary);
}

.headerBtn {
  margin-left: var(--unit-24);
}

.formField {
  margin-bottom: var(--unit-24);
}

.rateRightAddon {
  color: var(--color-content-primary);
  width: var(--unit-24);
}

.noVatAccountWarning {
  margin: var(--unit-16) 0;
}

.reverseChargeSection {
  margin-top: var(--unit-32);
}

.subtitle {
  margin-bottom: var(--unit-32);
}

.errorCallout {
  margin-bottom: var(--unit-16);
}

.defaultForm__inputWrapper {
  display: flex;
  flex-direction: row;
  gap: var(--unit-16);
  margin-top: var(--unit-24);
}

.defaultForm__input:first-child {
  margin-right: var(--unit-8);
}

.defaultForm__input:last-child {
  margin-left: var(--unit-8);
}

.defaultForm__input {
  margin-top: var(--unit-24);
  flex: 1 1 50%;
}

.defaultForm__submit {
  margin-top: var(--unit-24);
  margin-right: var(--unit-16);
}
