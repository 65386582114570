.navigation-item {
  position: relative;
  overflow-x: clip;
  text-align: start;
  gap: var(--unit-12);
  width: 100%;
  cursor: pointer;
  border-radius: var(--border-radius-8);
  background-color: transparent;
  color: var(--color-content-secondary-bg-secondary);
  font: var(--body-m);
}

:where(.navigation-item) {
  display: grid;
  grid-auto-columns: var(--unit-16) 1fr;
  grid-auto-flow: column;
  border: 0;
  align-items: center;
  padding: var(--unit-4) var(--unit-12);
}

.navigation-item:is(:hover, :focus-visible) {
  background: var(--color-background-navigation-item-hover);
  color: var(--color-content-secondary-bg-secondary);
}

.navigation-item:is(
    [aria-current='page'],
    :has(+ ul .navigation-item[aria-current='page'])
  ) {
  color: var(--color-content-primary);
  background: var(--color-background-navigation-item-active);
}

.navigation-item:focus-visible {
  outline: var(--focus-ring);
  outline-offset: 0px;
}

.navigation-toggle-display {
  opacity: 0;
  white-space: nowrap;
}

#vertical:hover .navigation-toggle-display {
  opacity: 1;
  translate: 0 0;
}

.popover-hack-align {
  --popover-offset: var(--unit-16);
}

@media (prefers-reduced-motion: no-preference) {
  .navigation-toggle-display {
    --_translate-offset: -2ch;
    translate: var(--_translate-offset) 0;
    transition:
      opacity var(--transition-duration) var(--transition-timing-function),
      translate var(--transition-duration) linear(0, 0.8 50%, 1);
  }
}

@container navigation (width > 230px) {
  .navigation-toggle-display {
    opacity: 1;
    translate: 0 0;
  }
}
