.RuleMembers__select {
  margin-bottom: var(--unit-24);
}

.RuleMembers__select button {
  color: var(--color-content-secondary-bg-secondary);
}

.RuleMembers__flash {
  text-align: left;
  margin-top: var(--unit-16);
}
