.ForwardInfos__content-description {
  margin: 0 auto;
  margin-top: var(--unit-8);
  line-height: 18px;
  max-width: 456px;
  color: var(--color-content-primary);
}

.ForwardInfos__content-description__link {
  text-decoration: underline;
  cursor: pointer;
}

.ForwardInfos__content__small {
  margin-top: var(--unit-16);
}
