.BudgetGaugeLegend {
  width: 100%;
  margin-top: var(--unit-16);
}
.BudgetGaugeLegend.BudgetGaugeLegend--small .BudgetGaugeLegend__content {
  grid-template-columns: repeat(2, 1fr);
  gap: var(--unit-8) var(--unit-16);
}
.BudgetGaugeLegend.BudgetGaugeLegend--small .BudgetGaugeLegend__item__label {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.BudgetGaugeLegend.BudgetGaugeLegend--full
  .BudgetGaugeLegend__item__label__amount {
  margin-left: calc(-1 * var(--unit-20));
}
.BudgetGaugeLegend.BudgetGaugeLegend--full .BudgetGaugeLegend__item__icon {
  margin-top: 3px;
}
.BudgetGaugeLegend.BudgetGaugeLegend--full .BudgetGaugeLegend__item__circle {
  margin-top: 3px;
  width: var(--unit-12);
  height: var(--unit-12);
}
.BudgetGaugeLegend__content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.BudgetGaugeLegend__content.BudgetGaugeLegend__content--full {
  grid-template-columns: 1fr 1fr;
}
.BudgetGaugeLegend__content.BudgetGaugeLegend__content--hovered
  .BudgetGaugeLegend__item {
  opacity: 0.4;
}
.BudgetGaugeLegend__content.BudgetGaugeLegend__content--hovered
  .BudgetGaugeLegend__item.BudgetGaugeLegend__item--hovered {
  opacity: 1;
  padding: var(--unit-8);
  margin: calc(-1 * var(--unit-8));
  margin-right: var(--unit-8);
  border-radius: var(--border-radius-4);
  /* background-color: var(--color-neutral-lightest);
  */
  cursor: default;
  /* TODO: uncomment 'show budget payments' will be available */
}
.BudgetGaugeLegend__item {
  text-decoration: none;
  outline: none;
  border: none;
  background: none;
  font-family: var(--ff-base);
  color: inherit;
  padding: var(--unit-8);
  margin: calc(-1 * var(--unit-8));
  margin-right: var(--unit-8);
  border-radius: var(--border-radius-4);
  display: flex;
  width: fit-content;
}
.BudgetGaugeLegend__item .BudgetGaugeLegend__item__caret {
  opacity: 0;
}
.BudgetGaugeLegend__item.BudgetGaugeLegend__item--button {
  cursor: pointer;
  position: relative;
  background-color: none;
  transition: background-color var(--transition-duration)
    var(--transition-timing-function);
}
.BudgetGaugeLegend__item.BudgetGaugeLegend__item--button > * {
  cursor: pointer;
}
.BudgetGaugeLegend__item.BudgetGaugeLegend__item--button
  .BudgetGaugeLegend__item__caret {
  opacity: 0;
  margin-top: var(--unit-8);
  margin-left: var(--unit-8);
  transition: opacity var(--transition-duration)
    var(--transition-timing-function);
}
.BudgetGaugeLegend__item.BudgetGaugeLegend__item--button:hover {
  background-color: var(--color-background-primary-hover);
}
.BudgetGaugeLegend__item.BudgetGaugeLegend__item--button:hover
  .BudgetGaugeLegend__item__caret {
  opacity: 1;
}
.BudgetGaugeLegend__item__circle {
  width: var(--unit-8);
  height: var(--unit-8);
  border-radius: 100%;
  margin-right: var(--unit-8);
  margin-top: 5px;
  flex-shrink: 0;
}
.BudgetGaugeLegend__item__icon {
  margin-right: var(--unit-8);
  margin-top: 5px;
}
