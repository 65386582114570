.codeInput {
  display: flex;
  justify-content: center;
  margin-top: var(--unit-16);
}

.codeInput input {
  appearance: none;
  -moz-appearance: textfield;
}

.codeInputt input::-webkit-inner-spin-button,
.codeInput input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.codeFeedback {
  margin-top: var(--unit-16);
}

.messages {
  text-align: center;
  margin-top: var(--unit-16);
}
