.LegacySubscriptionAddonItem {
  margin-bottom: var(--unit-8);
}

.LegacySubscriptionAddonItem .LegacySubscriptionItem {
  list-style: none;
}

.LegacySubscriptionAddonItem .LegacySubscriptionItem--addon {
  margin-bottom: 0;
}
