.LegacySubscriptionItem {
  width: 100%;
}

.LegacySubscriptionItem :not(:last-child) {
  margin-bottom: var(--unit-8);
}
.LegacySubscriptionItem--addon {
  list-style-type: none;
}
.LegacySubscriptionItem--addon::before {
  content: '+';
  position: absolute;
  margin-top: -2px;
  margin-left: calc(var(--unit-16) * -1);
  font-size: var(--font-size-16);
}

.LegacySubscriptionItem--addon .LegacySubscriptionItem__extra {
  color: var(--color-content-complementary);
}

.LegacySubscriptionItem__content {
  display: flex;
  justify-content: space-between;
}

.LegacySubscriptionItem__base {
  font-size: var(--font-size-14);
}

.LegacySubscriptionItem__extra {
  font-size: var(--font-size-14);
  color: rgb(255 255 255 / 0.8);
  text-align: right;
}
