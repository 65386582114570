.MarqetaSetPinSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.MarqetaSetPinSuccess__image {
  margin-bottom: var(--unit-24);
}

.MarqetaSetPinSuccess__message {
  text-align: center;
  padding: 0 var(--unit-48);
  font-size: var(--font-size-14);
}

.MarqetaSetPinSuccess__button {
  margin-top: var(--unit-24);
}
