@import 'reset';
@import 'fonts';

body {
  display: flex;
  flex-direction: column;
}

.page__container {
  grid-area: main;
  flex-direction: column;
  display: flex;
  overflow-y: auto;
  position: relative;
  height: 100%;
}

.sm-survey * {
  font-family: var(--ff-base) !important;
}

@media screen and (max-width: 925px) {
  #intercom-container {
    display: none !important;
  }

  #extension-installed {
    display: none;
  }

  .page__container {
    -webkit-overflow-scrolling: touch;
  }
}

.resetButtonStyle {
  text-decoration: none;
  outline: none;
  border: none;
  border-radius: 0;
  background: none;
  font-family: var(--ff-base);
  color: inherit;
}
