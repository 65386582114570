.BudgetOverviewPlaceholder__title {
  display: flex;
  align-items: center;
  color: var(--color-content-primary);
  margin-bottom: var(--unit-16);
  line-height: normal;
  min-height: var(--unit-24);
}

.BudgetOverviewPlaceholder__nums {
  line-height: 25px;
  font-size: 16px;
  margin-bottom: var(--unit-16);
  color: var(--color-content-secondary-bg-secondary);
}

.BudgetOverviewPlaceholder__nums__zero {
  font-size: 20px;
}

.BudgetOverviewPlaceholder__gauge {
  height: var(--unit-16);
  border: 1px solid var(--color-border-default);
  width: 100%;
  border-radius: var(--border-radius-4);
  position: relative;
}

.BudgetOverviewPlaceholder__gauge::after {
  content: '';
  background-color: var(--color-background-tertiary-default);
  width: 65%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: var(--border-radius-4);
}

.BudgetOverviewPlaceholder__gaugeLegend {
  width: 100%;
  margin-top: var(--unit-16);
}

.BudgetOverviewPlaceholder__gaugeLegend__content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.BudgetOverviewPlaceholder__gaugeLegend__item {
  line-height: normal;
}

.BudgetOverviewPlaceholder__gaugeLegend__item__label {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.BudgetOverviewPlaceholder__gaugeLegend__item__label__amount {
  display: flex;
  justify-content: left;
  flex-direction: row;
}

.BudgetOverviewPlaceholder__gaugeLegend__item__circle {
  width: var(--unit-8);
  height: var(--unit-8);
  border-radius: 100%;
  margin-right: var(--unit-8);
  margin-top: 6px;
  flex-shrink: 0;
}

.BudgetOverviewPlaceholder__gaugeLegend__item__circle--used {
  background-color: var(--color-background-tertiary-default);
}

.BudgetOverviewPlaceholder__gaugeLegend__item__circle--available {
  background-color: var(--color-background-primary-default);
  border: 1px solid var(--color-border-default);
}

.BudgetOverviewPlaceholder__gaugeLegend__item__label__name,
.BudgetOverviewPlaceholder__gaugeLegend__item__label__amount {
  color: var(--color-content-secondary-bg-secondary);
}

.BudgetOverviewPlaceholder__gaugeLegend__item__label {
  text-align: left;
}

.BudgetOverviewPlaceholder__gaugeLegend__item__label__amount {
  white-space: nowrap;
}
