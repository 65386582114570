.MembersTable__name {
  display: flex;
  align-items: center;
  overflow: hidden;
  gap: var(--unit-16);
}

.MembersTable__name__name {
  display: grid;
}
.MembersTable__name__name__name {
  color: var(--color-content-primary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.MembersTable__name__name__email {
  color: var(--color-content-secondary-bg-primary);
}
/*  re-init table display, otherwise we lose the "table" layout */
.MembersTable thead tr th {
  white-space: nowrap;
}

.MembersTable__textPrimary {
  color: var(--color-content-primary);
}
.MembersTable__textSecondary {
  color: var(--color-content-primary);
}
.MembersTable__iconContainer {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.MembersTable__iconContainer svg {
  margin-left: var(--unit-8);
  color: var(--color-content-secondary-bg-secondary);
}

.MembersTable.MembersTable__isPending tbody tr td > *,
.MembersTable.MembersTable__isPending .MembersTable__name__name__name {
  color: var(--color-content-primary);
}
