.GaugeBar {
  width: 100%;
  display: flex;
}
.GaugeBar.GaugeBar--clickable:hover .GaugeBar__segment--bypass-hover {
  opacity: 1 !important;
}
.GaugeBar.GaugeBar--clickable:hover .GaugeBar__segment:not(:hover) {
  opacity: 0.8;
}
.GaugeBar.GaugeBar--has-animation {
  animation: expanding 400ms ease-in-out forwards;
}
.GaugeBar.GaugeBar--withRoundedSegments {
  background-color: var(--color-background-tertiary-default);
  border-radius: 23px;
}
.GaugeBar.GaugeBar--withRoundedSegments .GaugeBar__segment {
  border-right: none;
}
.GaugeBar.GaugeBar--withRoundedSegments .GaugeBar__segment:first-child {
  border-radius: 23px;
}
.GaugeBar.GaugeBar--withRoundedSegments .GaugeBar__segment:last-child {
  border-right: none;
  border-top-right-radius: 23px;
  border-bottom-right-radius: 23px;
}
.GaugeBar__segment {
  height: 100%;
  border-right: 2px solid var(--color-border-default);
}
.GaugeBar__segment:first-child {
  border-top-left-radius: var(--border-radius-4);
  border-bottom-left-radius: var(--border-radius-4);
}
.GaugeBar__segment:last-child {
  border-right: none;
  border-top-right-radius: var(--border-radius-4);
  border-bottom-right-radius: var(--border-radius-4);
}
.GaugeBar__segment > div {
  /* tooltip container */
  height: var(--unit-16);
  width: 100%;
}
.GaugeBar__segment.GaugeBar__segment--clickable {
  cursor: pointer;
}
@keyframes expanding {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
