.VendorSyncStatus .SyncStatusRow {
  display: flex;
  align-items: center;
}

.VendorSyncStatus .SyncStatusRow .statusSyncIcon {
  width: var(--unit-12);
}

.VendorSyncStatus .SyncStatusRow .statusSyncIcon.synced {
  color: var(--color-content-success-default);
}

.VendorSyncStatus .SyncStatusRow .label {
  font-weight: var(--fw-semibold);
  color: var(--color-content-primary);
  margin-left: var(--unit-8);
  padding-top: 2px;
}

.VendorSyncStatus .SyncStatusRow .label a {
  text-decoration: none;
}

.VendorSyncStatus .SyncStatusRow .label .externalLinkIcon {
  width: 10px;
  margin-left: var(--unit-4);
  vertical-align: middle;
  padding-bottom: 1px;
  color: var(--color-content-primary);
}

.VendorSyncStatus .SyncStatusRow .label a:hover {
  text-decoration: underline;
}

.VendorSyncStatus.hasDefaultVendorAnchor,
.VendorSyncStatus.hasCurvyAnchor {
  position: relative;
  height: 36px;
}

.VendorSyncStatus.hasDefaultVendorAnchor.hasCurvyAnchor {
  position: relative;
  height: var(--unit-48);
}

.VendorSyncStatus.hasDefaultVendorAnchor.hasCurvyAnchor + aside {
  margin-top: var(--unit-20);
}

.VendorSyncStatus.hasCurvyAnchor .curvyAnchor {
  width: 13px;
  height: 23px;
  position: absolute;
  top: 0;
  left: var(--unit-4);
  border: 2px solid var(--color-border-default);
  border-top: 0;
  border-right: 0;
  border-radius: 0 0 0 var(--border-radius-8);
}

.VendorSyncStatus.hasCurvyAnchor .SyncStatusRow {
  position: absolute;
  top: 10px;
  left: var(--unit-20);
}

.VendorSyncStatus.hasDefaultVendorAnchor .defaultVendorAnchor {
  height: 10px;
  position: absolute;
  top: 0;
  left: var(--unit-4);
  border-left: 2px solid var(--color-border-default);
}

.VendorSyncStatus.hasDefaultVendorAnchor .DefaultVendorRow {
  position: absolute;
  top: var(--unit-8);
  display: flex;
  align-items: center;
}

.VendorSyncStatus.hasDefaultVendorAnchor .DefaultVendorRow .defaultVendorIcon {
  height: 14px;
  margin-right: var(--unit-4);
}

.VendorSyncStatus.hasDefaultVendorAnchor .DefaultVendorRow span {
  padding-top: 2px;
}

.VendorSyncStatus.hasDefaultVendorAnchor .DefaultVendorRow .defaultVendorName {
  margin-right: var(--unit-4);
  font-weight: var(--fw-semibold);
  color: var(--color-content-primary);
}

.VendorSyncStatus.hasDefaultVendorAnchor
  .DefaultVendorRow
  .missingDefaultVendor {
  font-weight: var(--fw-semibold);
  color: var(--color-content-warning-default);
}

.VendorSyncStatus.hasDefaultVendorAnchor.hasCurvyAnchor .curvyAnchor {
  top: 28px;
}

.VendorSyncStatus.hasDefaultVendorAnchor.hasCurvyAnchor .SyncStatusRow {
  top: 38px;
}
