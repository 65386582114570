.RequestsPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 var(--unit-40);
}

.RequestsPage__container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
