.CustomFieldCreationModal__values {
  position: relative;
  margin-bottom: var(--unit-8);
}

.CustomFieldCreationModal__values__counter__tooMany {
  color: var(--color-content-alert-default);
}

.CustomFieldCreationModal__values__callout {
  text-align: left;
}

.CustomFieldCreationModal__values__callouts {
  display: grid;
  gap: var(--unit-8);
}

.CustomFieldCreationModal__content__callouts {
  margin-top: calc(var(--unit-16) * -1);
  margin-bottom: var(--unit-16);
  text-align: left;
}

.CustomFieldCreationModal__values__counter {
  position: absolute;
  bottom: 6px;
  right: 6px;
  color: var(--color-content-secondary-bg-secondary);
}
