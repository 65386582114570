.MembersTabList {
  background: var(--color-background-primary-default);
  border: 1px solid var(--color-border-default);
  border-bottom: 0px;
  border-radius: var(--border-radius-8) var(--border-radius-8) 0 0;
  padding: 0 var(--unit-4) 0;
  margin-bottom: -1px;
}

.MembersTabList::after {
  height: 1px;
  background: var(--color-border-default);
}

.MembersTab {
  gap: var(--unit-8);
}
