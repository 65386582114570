.ImageViewer {
  position: relative;
  border-radius: var(--border-radius-8);
  background: var(--color-background-primary-default);
  height: 100%;
}

.ImageViewer__zoomboxContainer {
  overflow-y: auto;
  height: calc(100% - var(--unit-40) /* Toolbar height */);
  border-radius: 0 0 var(--border-radius-8) var(--border-radius-8);
}

.ImageViewer__zoombox {
  overflow: hidden;
}

.ImageViewer__image {
  display: block;
  width: 100%;
  height: auto;
  transition: opacity 0.2s ease-in-out;
}

.ImageViewer__image + .ImageViewer__image {
  display: none;
}

.FileViewerToolbar {
  border-top-left-radius: var(--border-radius-4);
  border-top-right-radius: var(--border-radius-4);
}

.ImageViewer__canvas {
  display: none;
  width: 100%;
}

.ImageViewer--rotating {
  cursor: wait;
}

.ImageViewer--rotating .ImageViewer__image {
  opacity: 0.25;
  cursor: wait !important;
}
