.AddressForm__form-field:not(:first-of-type) {
  margin-top: var(--unit-24);
}

.AddressForm__field-container {
  display: flex;
  margin-top: var(--unit-24);
  gap: var(--unit-16);
}

.AddressForm__field-container .AddressForm__form-field {
  flex: 1 1 50%;
  margin: 0;
  position: relative;
}
