.CompanyPlanSubscriptionSection {
  padding: var(--unit-16);
  display: flex;
  flex-direction: column;
}

.CompanyPlanSubscriptionSection:not(:last-child) {
  border-right: 1px solid var(--color-border-default);
}

.CompanyPlanSubscriptionSection h2 {
  color: var(--color-content-secondary-bg-primary);
  margin-bottom: var(--unit-16);
  font-size: var(--body-m);
  font-weight: var(--fw-medium);
}
