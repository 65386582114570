.BudgetOverviewButton {
  position: relative;
  width: 100%;
  border: none;
  background: none;
}

.BudgetOverviewButton * {
  cursor: pointer;
}

.BudgetOverviewButton > * {
  transition: border-color var(--transition-duration)
    var(--transition-timing-function);
}

.BudgetOverviewButton:hover > * {
  box-shadow: var(--elevation-l);
  border-color: var(--color-border-hover);
}

.BudgetOverviewButton:active,
.BudgetOverviewButton:focus {
  outline: none;
}
