.AffidavitSignatureModal .Modal-wrapper {
  overflow: hidden;
}

.AffidavitSignatureModal .Modal-wrapper .Modal-content {
  padding: 0;
}

.AffidavitSignatureModal .Modal-wrapper .Modal-title-wrapper .Modal-close {
  top: var(--unit-24);
}

.AffidavitSignatureModal__signature {
  height: 80vh;
}

.AffidavitSignatureModal__signature .Signature {
  box-shadow: none;
}

.AffidavitSignatureModal__footer {
  padding-left: var(--unit-24);
  padding-top: var(--unit-8);
  padding-bottom: var(--unit-8);
}
