.ReceiptFilter__button {
  color: var(--color-content-primary);
  font-weight: var(--fw-medium);
}

.ReceiptFilter__button--open {
  background-color: var(--color-background-secondary-default);
}

.ReceiptFilter__button--active {
  color: var(--color-content-brand-default);
  font-weight: var(--fw-semibold);
}
