:root {
  --card-max-width: 470px;
}

.root {
  align-items: center;
  border: var(--unit-16) solid var(--color-background-primary);
  background-color: #f8f9ff; /* Grapes hacking time: to be replaced by Secondary / Blue 5 */
  border-radius: var(--unit-16);
  display: flex;
  max-width: var(--card-max-width);
}
