.AccountPayableSuppliersCreateSupplierForm__field {
  margin: var(--unit-16) 0;
  text-align: left;
}

.AccountPayableSuppliersCreateSupplierForm__supplier-icon {
  width: var(--unit-24);
  height: var(--unit-24);
  border-radius: var(--border-radius-4);
}

.AccountPayableSuppliersCreateSupplierForm__actions {
  gap: var(--unit-16);
  margin-top: var(--unit-24);
  margin-bottom: calc(-1 * var(--unit-16));
  display: flex;
  justify-content: center;
}

.AccountPayableSuppliersCreateSupplierForm__fieldWrapper {
  display: flex;
  margin: var(--unit-16) 0;
}

.AccountPayableSuppliersCreateSupplierForm__fieldWrapper
  .AccountPayableSuppliersCreateSupplierForm__field {
  flex: 1 1 50%;
  margin: 0;
  position: relative;
}

.AccountPayableSuppliersCreateSupplierForm__fieldWrapper
  .AccountPayableSuppliersCreateSupplierForm__field:not(:first-child) {
  flex: 1 1 50%;
  margin-left: var(--unit-16);
}
