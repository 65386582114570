.LayoutContainer {
  display: grid;
  height: 100vh;
  grid-template-columns: var(--ui-size-nav) 1fr;
  grid-template-areas:
    'banner banner'
    'nav main';
  grid-template-rows: max-content 1fr;
  background: var(--color-background-primary-default);
}
