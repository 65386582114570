:root {
  --bar-height: 80px;
}

.children {
  background-color: var(--color-background-primary-default);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius-8);
  margin: auto;
  padding: var(--unit-24);
  width: min(100%, 632px);
}

.body {
  display: flex;
  margin-bottom: var(--bar-height);
}

.bodyWithHeader {
  height: calc(100vh - 2 * var(--bar-height));
  margin-top: var(--bar-height);
}

.bodyWithoutHeader {
  height: calc(100vh - var(--bar-height));
}

.section {
  display: flex;
  flex-direction: column;
  padding: var(--unit-40) var(--unit-64);
  overflow-y: scroll;
}

.sectionLeft {
  flex: 44;
}

.sectionRight {
  background-color: var(--color-background-secondary-default);

  flex: 56;
}
