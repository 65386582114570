.LegacyCompanyPlanSubscription__TopInfo {
  background-color: var(--color-background-complementary-default);
  color: var(--color-content-complementary);
  padding: 30px;
  border-radius: var(--border-radius-4);
  position: relative;
  margin-top: var(--unit-16);
  margin-bottom: 15px;
  min-height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.LegacyCompanyPlanSubscription__TopInfo:hover {
  background-color: rgb(255 255 255/0.5);
}

.LegacyCompanyPlanSubscription__TopInfo-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px var(--unit-32);
  color: var(--color-content-complementary);
  border: none;
  border-radius: var(--border-radius-4);
  background-color: rgb(255 255 255/0.4);
  transition: background-color 0.2s ease;
  font-size: var(--font-size-14);
  font-weight: var(--fw-semibold);
  cursor: pointer;
  outline: 0;
}

.LegacyCompanyPlanSubscription__TopInfo-message {
  margin-bottom: 30px;
}

.LegacyCompanyPlanSubscription__ChangePlan {
  margin-right: var(--unit-8);
}

.LegacyCompanyPlanSubscription__BranchCta {
  display: flex;
  align-items: center;
  margin-bottom: var(--unit-40);
}

.LegacyCompanyPlanSubscription__TopInfo__parentCompany {
  margin-bottom: var(--unit-32);
}
