.AccountingBaseInput {
  display: flex;
  flex-direction: row;
  height: 36px;
}

.AccountingBaseInput__edit {
  margin-left: var(--unit-16);
  width: 154px;
}

.AccountingBaseInput__input {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none !important;
  background-color: var(--color-background-secondary-default) !important;
  box-shadow: none !important;
  width: 544px;
  border-radius: var(--border-radius-4);
}

.AccountingBaseInput__input input {
  cursor: default !important;
  color: var(--color-content-primary) !important;
}

.AccountingBaseInput__rightIcon {
  padding: var(--unit-4) var(--unit-8) 0 var(--unit-8);
  color: var(--color-content-primary);
}

.AccountingBaseToggle input {
  cursor: pointer !important;
}
