.accountsPayableTable thead {
  display: table-header-group;
}
.accountsPayableTable thead tr th {
  white-space: nowrap;
}
.accountsPayableTable tbody {
  display: table-row-group;
}
.accountsPayableTable tr {
  display: table-row;
}
.accountsPayableTable tr:first-child td {
  border-top: 1px solid var(--color-border-default);
}
.accountsPayableTable {
  table-layout: fixed;
}
