.container {
  position: relative;
}

.accordion {
  background-color: var(--color-background-primary-default);
  border-top: 1px solid var(--color-border-default);
  transition: background-color var(--transition-duration)
    var(--transition-timing-function);
}

.accordion:not([aria-expanded='true']):hover {
  background-color: var(--color-background-primary-hover);
}

.disabled {
  background-color: var(--color-background-primary-default);
}

.illustrationContainer {
  width: 18%;
  max-width: 170px;
  margin-right: 77px;
  padding-bottom: var(--unit-24);
  display: flex;
  align-items: center;
}

.helpCenterLink {
  color: inherit;
  text-decoration: underline;
}
