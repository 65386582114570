.Subscription {
  width: 100%;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--unit-20);
  font-size: var(--font-size-16);
}
.Subscription__name {
  font-weight: var(--fw-semibold);
  text-transform: capitalize;
}

.Subscription__pricing {
  display: flex;
  flex-direction: column;
}

.Subscription__pricing-amount--crossed {
  text-decoration: line-through;
  margin-right: 10px;
}

.Subscription__pricing-discounts {
  margin-top: 5px;
  text-align: right;
  font-size: var(--font-size-12);
  color: rgb(255 255 255/0.8);
}
