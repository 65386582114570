.ProfilePage {
  width: min(100%, var(--unit-container-lg));
}

.ProfilePage__TabContainer {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  width: 100%;
}

.ProfilePage__TabContainer > section:not(:last-child) {
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid var(--color-border-default);
}
