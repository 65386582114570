.PbrRuleTimeline__desc__row {
  display: flex;
  align-items: center;
  margin-bottom: var(--unit-32);
}

.PbrRuleTimeline__desc__row:last-child {
  margin-bottom: 0;
}

.PbrRuleTimeline__desc__row__icon__line {
  position: absolute;
  content: '';
  width: 2px;
  height: calc(var(--unit-32) - 1px);
  border-radius: 1px;
  top: calc(100% + 6px);
  background-color: var(--color-border-default);
}

.PbrRuleTimeline__desc__row__icon {
  margin-right: var(--unit-16);
  width: 34px; /*  biggest icon width */
  display: flex;
  justify-content: space-around;
  position: relative;
  flex-shrink: 0;
}

.PbrRuleTimeline__desc__row__icon:after,
.PbrRuleTimeline__desc__row:last-child .PbrRuleTimeline__desc__row__icon__line {
  display: none;
}
