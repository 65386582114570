.CostCenterImportModalError__error {
  margin-bottom: var(--unit-24);
  position: relative;
}

.CostCenterImportModalError__error:nth-child(2) {
  padding-top: var(--unit-24);
}

/*  divider line */
.CostCenterImportModalError__error:nth-child(2):before {
  position: absolute;
  width: 100%;
  border-bottom: 1px solid var(--color-border-default);
  content: '';
  top: 0;
  left: calc(50% - 130px);
  width: 260px;
}

.CostCenterImportModalError__error p {
  color: var(--color-content-primary);
}

.CostCenterImportModalError__entities_list {
  margin-top: var(--unit-24);
}
