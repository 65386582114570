.releaseNavigationItem {
  position: relative;
  overflow-x: clip;
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: var(--unit-12);
  width: 100%;
  cursor: pointer;
  border-radius: var(--border-radius-8);
  color: var(--color-content-primary);
  font: var(--body-m);
  padding: var(--unit-12);
  background: linear-gradient(
    269.01deg,
    rgba(218, 209, 255, 0.6) 1.7%,
    rgba(243, 240, 255, 0.6) 98.3%
  );
  backdrop-filter: blur(9.836078643798828px);
  box-shadow: 0px 4px 4px 0px #00000005;
  transition: max-height var(--transition-duration-long) ease-in-out;
  max-height: 44px;
}

.releaseContent {
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition:
    opacity 0.3s ease-in-out,
    height 0.3s ease-in-out;
}

.releaseNavigationItem:hover .releaseContent {
  opacity: 1;
  height: auto;
}

.releaseNavigationItem:hover {
  max-height: 210px;
}
