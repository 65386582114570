.AccountingBasePage__feature {
  border: 1px solid #dddde6;
  border-radius: var(--border-radius-4);
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--color-background-primary-default);
}

.AccountingBasePage__feature-title {
  line-height: var(--lh-regular);
  color: var(--color-content-primary);
  margin-bottom: var(--unit-8);
}

.AccountingBasePage__feature-content {
  color: var(--color-content-primary);
}

.AccountingBasePage__feature-actions {
  margin-top: var(--unit-24);
}

.AccountingBasePage__feature-switch {
  color: var(--color-content-primary);
  display: flex;
  margin-top: var(--unit-24);
  align-items: center;
}
