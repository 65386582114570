.nav {
  grid-area: nav;
  display: flex;
  flex-direction: column;
  container-name: navigation;
  container-type: inline-size;
  background-color: var(--color-background-secondary-default);
  z-index: calc(var(--z-index-dropdown) + 1);
  width: var(--ui-size-nav);
  max-height: 100vh;
  min-height: 0px;
  transition: width var(--transition-duration) var(--transition-timing-function);
  /** animation disclore height **/
  interpolate-size: allow-keywords;
}

.nav:is(
    :hover,
    :has(:focus-visible),
    :has([role='dialog']),
    :has([aria-expanded='true'])
  ) {
  width: var(--ui-size-nav-expanded);
  box-shadow: var(--ui-elevation-nav, var(--elevation-l));
}

.top {
  flex-shrink: 0;
  padding: var(--unit-12) var(--unit-12) 0;
  gap: var(--unit-16);
  display: flex;
  flex-direction: column;
}

.content {
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
  padding: var(--unit-8) var(--unit-12);
}

@supports (animation-timeline: scroll()) {
  .content::after {
    position: sticky;
    content: '';
    display: block;
    bottom: calc(-1 * var(--unit-8));
    right: 0;
    left: 0;
    height: 1px;
    opacity: 0;
    background-color: var(--color-border-default);
    animation: sticky-separator linear both;
    animation-timeline: scroll();
  }
  @keyframes sticky-separator {
    from {
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
}

.footer {
  flex-shrink: 0;
  padding: var(--unit-12);
}
